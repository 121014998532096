import meetingConfig from 'meetingConfig';
import _ from 'lodash';
import qs from 'qs';
import {
  CAPS_OPTION_SUPPORT_GALLERY_VIEW_SORT_TYPE,
  CAPS_OPTION_SUPPORT_ZOOM_PRESENCE,
  DISABLE_MESSAGE_TYPES_ON_TESLA,
  LOCALSTORAGE_KEYS,
  PARTICIPANT_CAN_BE_ASSIGNED_HUGE_BO,
  PARTICIPANT_NEED_ASK_UNMUTE,
  SESSIONSTORAGE_KEYS,
  WEBINAR_CLIENT_CAP_CAN_MUTE,
  WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL,
  WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_IS_H323,
  WEBNIAR_CLIENT_CAP_SUPPORT_IS_CRC_FREE,
  CAPS_OPTION_SUPPORT_LOCAL_LIVESTREAMING,
} from '../constant';
import { CMR_RECORDING_STATUS, RECORDING_STATUS, RWG_USER_TYPE } from '../enum';
import {
  isChromeOS,
  isExternalControlledMode,
  isOculus,
  isSupportAV,
  isSupportMultiView,
  isTeslaMode,
  iText,
  isMTRAndroid,
  isFirefox,
  isWindows,
  isMac,
  showBrowserUpgradeBanner,
  isMobileDevice,
} from '../util';
import * as userTypes from '../../constants/UserRoles';
// import { isInRoom } from '../../features/breakout-room/utils';
import { storeType } from '../easy-store/easy-store';
import { GLOBAL_FOOTER_TOOLBAR_HEIGHT } from '../../features/sharing/enum';
import { ATTENDEE_STATUS } from '../../features/breakout-room/constant';
import { INTERPRETER } from '../../features/interpretation/resource';
import { hostText } from '../../features/meeting-info/resource';
import { PollItemState } from '../../features/poll/constants';
import { globalVariable } from '../global-variable';
import { USER_STATUS_FAILOVER } from '../../constants/Constants';
import {
  isEnableAudioDenoiseFlag,
  isEnableWaitingRoomJoinWithoutFailoverFlow,
  isEnableVBForFirefox,
  isSwitchToOneChatList,
} from '../op-feature-option';
import { isWebinar } from './meeting-types';
import { isHost, isPanelist, isViewOnly } from './user-types';
import {
  getBaseUrl,
  getMediasdkBaseUrl,
} from '../../tasks/global/task-utils/common-utils';
import { getLaplaceReportOnceFunc } from '../logger/log-service/report-once';
import { easyStore } from '../easy-store';
import { getFinalUseWebRTC } from './webrtc-service';
import { isAudioBridge } from './audio-bridge-service';

//temp workaround to fix circular import
function isInRoom(status) {
  return status === ATTENDEE_STATUS.IN_ROOM;
}
export function isH323User(user) {
  return user && user.userType === RWG_USER_TYPE.H323;
}

export function isVirtualUser(user) {
  return user && user.userType === RWG_USER_TYPE.RSGW;
}

// helper function: check if user is RMC Admin
export function isSpecialAdmin(user) {
  return !!user.isAdmin;
}

/**
 * @warn you need to use `isQnASettingEnabledSelector`
 * @returns boolean
 */
export function isQAWebSettingEnabled() {
  return (
    meetingConfig.isSupportQA || meetingConfig.meetingOptions?.isSupportNewQA
  );
}
export function isBreakoutRoomEnabled() {
  return meetingConfig.meetingOptions.isEnableBreakoutRoom;
}

export function isMySelf(currentUserId, user) {
  return currentUserId === user.userId || currentUserId === user.node;
}

export function isEnableWebinarChat() {
  return meetingConfig.meetingOptions?.isEnableWebinarChatInOp;
}

const isShowGuestLabelVisible = (user) =>
  user.isGuest == null || (user.isGuest && !user.isAdmin);

export const shouldShowGuestLabel = ({
  isCurrentViewOnly,
  isCurrentUserGuest,
  isUserGuest,
}) => {
  return (
    meetingConfig.meetingOptions.isEnableAlertGuestJoin &&
    !isCurrentUserGuest &&
    !isCurrentViewOnly &&
    isUserGuest
  );
};

const dnSuffixGeneratorFunc = [
  [(currentUserId, user) => isHost(user.userRole), hostText],
  [
    (currentUserId, user) => !isHost(user.userRole) && user.bCoHost,
    iText('Co-host', 'apac.wc_co_host_2'),
  ],
  [
    (currentUserId, user) => isMySelf(currentUserId, user),
    iText('Me', 'apac.wc_chat.me'),
  ],
  [
    (currentUserId, user, isCurrentUserGuest, isCurrentViewOnly) => {
      return shouldShowGuestLabel({
        isCurrentViewOnly,
        isCurrentUserGuest,
        isUserGuest: user.node ? user.bGuest : isShowGuestLabelVisible(user),
      });
    },
    iText('Guest', 'apac.wc_guest'),
  ],
  [
    (
      currentUserId,
      user,
      isCurrentUserGuest,
      isCurrentViewOnly,
      isUserSignLanguageInterpreter,
    ) => user.isInterpreter || isUserSignLanguageInterpreter,
    INTERPRETER,
  ],
];

// Shows the label after a user's display name, like
// UserA (co-host, me, interpreter)
export function getDisplayNameLabel(
  currentUserId,
  user,
  isCurrentUserGuest,
  isCurrentViewOnly,
  isUserSignLanguageInterpreter = false,
) {
  const tempSuffix = dnSuffixGeneratorFunc.reduce(
    (suffix, [testFunc, showStr]) =>
      suffix +
      (testFunc(
        currentUserId,
        user,
        isCurrentUserGuest,
        isCurrentViewOnly,
        isUserSignLanguageInterpreter,
      )
        ? suffix
          ? `, ${showStr}`
          : showStr
        : ''),
    '',
  );
  return tempSuffix ? `(${tempSuffix})` : '';
}

export function isWebinarClientSupportAllowToTalk(clientCap) {
  return (
    (clientCap & WEBINAR_CLIENT_CAP_CAN_MUTE) === WEBINAR_CLIENT_CAP_CAN_MUTE
  );
}

export function isWebinarAttendeeH323User(clientCap) {
  return (
    (clientCap & WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_IS_H323) ===
    WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_IS_H323
  );
}

export function isWebinarClientSupportCMDChat(clientCap) {
  return (
    (clientCap & WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL) ===
    WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL
  );
}

export function isWebinarAttendeeCRCFree(clientCap) {
  return (
    (clientCap & WEBNIAR_CLIENT_CAP_SUPPORT_IS_CRC_FREE) ===
    WEBNIAR_CLIENT_CAP_SUPPORT_IS_CRC_FREE
  );
}

// get user according user id
export function getUserById(users, userId) {
  return _.find(users, (user) => user.userId === userId);
}

export function getUserByJid(users, userJid) {
  return _.find(users, (user) => user.jid === userJid);
}

export function getXmppUserById(xmppUsers, userId) {
  return _.find(xmppUsers, (user) => user.node === userId);
}

export function getUserByXmppUserNode(meetingAttendees, node) {
  return _.find(meetingAttendees, (a) => a.userId === node);
}

export function getInMeetingParticipantsFromList(users) {
  return _.filter(users, (user) => !user.bHold);
}

export function getPanelistsAndHostFromList(users) {
  return _.filter(users, (user) => {
    const { userRole } = user;
    return isPanelist(userRole) || isHost(userRole);
  });
}

export const isHostGroup = (role) =>
  _.includes(
    [
      userTypes.USER_ROLE_HOST,
      userTypes.USER_ROLE_OWNER,
      userTypes.UESR_ROLE_OWNERHOST,
    ],
    role,
  );

export function isOwner(role) {
  return (role & userTypes.USER_ROLE_OWNER) === userTypes.USER_ROLE_OWNER;
}

export const isCoHost = (user) => user.bCoHost;

export const isCoOrHost = (user) =>
  isHost(user.userRole || user.role) || user.bCoHost;

export function sortParticipantsList(users, currentUser) {
  let group = 'other';
  const participants = users.reduce(
    (tmp, user) => {
      if (isZRMultiStreamVideoChildUser(user)) {
        tmp[group].push(user);
      } else if (user.userId === currentUser.userId) {
        tmp.me.push(user);
        group = 'me';
      } else if (isHost(user.userRole || user.role)) {
        tmp.host.push(user);
        group = 'host';
      } else if (isCoHost(user)) {
        tmp.coHost.push(user);
        group = 'coHost';
      } else if (user.bRaiseHand) {
        tmp.raiseHands.push(user);
        group = 'raiseHands';
      } else {
        tmp.other.push(user);
        group = 'other';
      }
      return tmp;
    },
    {
      me: [],
      host: [],
      coHost: [],
      raiseHands: [],
      other: [],
    },
  );
  return Object.keys(participants).reduce(
    (tmp, userType) => tmp.concat(participants[userType]),
    [],
  );
}

export function sortParticipantsListWithoutMe(users, currentUser) {
  const participants = users.reduce(
    (tmp, user) => {
      if (user.userId === currentUser.userId) {
        tmp.me.push(user);
      } else if (isHost(user.userRole || user.role)) {
        tmp.host.push(user);
      } else if (isCoHost(user)) {
        tmp.coHost.push(user);
      } else if (user.bRaiseHand) {
        tmp.raiseHands.push(user);
      } else {
        tmp.other.push(user);
      }
      return tmp;
    },
    {
      me: [],
      host: [],
      coHost: [],
      raiseHands: [],
      other: [],
    },
  );
  delete participants.me;
  return Object.keys(participants).reduce(
    (tmp, userType) => tmp.concat(participants[userType]),
    [],
  );
}

export function orderByCountryCode(codes) {
  return _.orderBy(codes, 'value', 'asc');
}

export function getDisplayName(participant, currentUserId, isCurrentUserGuest) {
  const name = participant.displayName || participant.name;
  const label = getDisplayNameLabel(
    currentUserId,
    participant,
    isCurrentUserGuest,
  );
  return `${name} ${label}`;
}

export function isZoomDomain(url) {
  const urlStrings = url.split('/');
  if (urlStrings.length < 3) {
    return false;
  }
  const domain = urlStrings[2];
  if (domain.indexOf('zoom') > -1) {
    return true;
  }
  return false;
}

export function getDefaultAvatar() {
  return `${getBaseUrl()}/image/default-avatar.png`;
}

export function getAvatarText(displayName = '') {
  const paths = displayName.toUpperCase().trim().split(/\s+/);

  if (paths.length === 1) {
    return paths[0][0];
  }

  if (paths.length >= 1) {
    const text = paths[0][0] + paths[1][0];
    if (/[\u4E00-\u9FA5]+/.test(text)) {
      return paths[0][0];
    }
    return text;
  }

  return '';
}

export function getAvatarBKColor(displayName = '', grayBK = false) {
  const arrayAvatarBK = [
    '#27ae60',
    '#16a085',
    '#2980b9',
    '#8e44ad',
    '#34495e',
    '#f39c12',
    '#d35400',
    '#c0392b',
  ];

  const arrayAvatarBKGray = [
    '#3327ae60',
    '#3316a085',
    '#332980b9',
    '#338e44ad',
    '#3334495e',
    '#33f39c12',
    '#33d35400',
    '#33c0392b',
  ];

  let index = 0;
  for (let i = 0; i < displayName.length; i++) {
    index += displayName.charCodeAt(i);
    index %= 8;
  }

  return grayBK ? arrayAvatarBKGray[index] : arrayAvatarBK[index];
}

export function getParticipantsListItemAvatarNew(
  participant,
  h323Avatar_no_used,
  defaultAvatar_no_used,
  bAllowedAvatar,
  isLarge,
) {
  if (!bAllowedAvatar) {
    return getDefaultAvatar();
  }
  if (isH323User(participant)) {
    return meetingConfig.h323Avatar;
  }
  if (participant.avatar) {
    const urls = participant.avatar.split('?');
    if (isZoomDomain(urls[0])) {
      if (isLarge) {
        return urls.length > 1
          ? participant.avatar
          : `${participant.avatar}?type=large`;
      }
      return participant.avatar;
    }
  }
  return getDefaultAvatar();
}

export function getWorkerPath() {
  const jsMediaSdkFullPath = getMediasdkBaseUrl();
  return {
    audioWorkerPath: `${jsMediaSdkFullPath}/js_audio_process.min.js`,
    audioWorkletPath: `${jsMediaSdkFullPath}/js_audio_worklet.min.js`,
    audioWorkletSIMDPath: `${jsMediaSdkFullPath}/js_audio_worklet_simd.min.js`,
    audioWorkletProcessPath: `${jsMediaSdkFullPath}/js_audio_worklet_process.min.js`,
    audioWasm: `${jsMediaSdkFullPath}/audio.encode.wasm`,
    videoWorkerPath: `${jsMediaSdkFullPath}/video_s.min.js`,
    videoMtWorkerPath: `${jsMediaSdkFullPath}/video_m.min.js`,
    videoWasm: `${jsMediaSdkFullPath}/video.decode.wasm`,
    videoMtWasm: `${jsMediaSdkFullPath}/video.mt.wasm`,
    sharingWorkerPath: `${jsMediaSdkFullPath}/sharing_s.min.js`,
    sharingMtWorkerPath: `${jsMediaSdkFullPath}/sharing_m.min.js`,
    videoSIMDWorkerPath: `${jsMediaSdkFullPath}/video_simd.min.js`,
    videoSIMDWasm: `${jsMediaSdkFullPath}/video.simd.wasm`,
    sharingSIMDWorkerPath: `${jsMediaSdkFullPath}/sharing_simd.min.js`,
    videoMSIMDWasm: `${jsMediaSdkFullPath}/video.mtsimd.wasm`,
    sharingMSIMDWorkerPath: `${jsMediaSdkFullPath}/sharing_mtsimd.min.js`,
    videoMSIMDWorkerPath: `${jsMediaSdkFullPath}/video_mtsimd.min.js`,
    audioSIMDWorkletPath: `${jsMediaSdkFullPath}/audio_simd.min.js`,
    audioSIMDWasm: `${jsMediaSdkFullPath}/audio.simd.wasm`,
    vsmiworkerpath: `${jsMediaSdkFullPath}/video_share_mtsimd.min.js`,
    sharingAudioWorkletPath: `${jsMediaSdkFullPath}/js_sharing_audio_worklet.min.js`,
  };
}

export function getRWGFromURL(commandSocketUrl) {
  if (!_.isEmpty(commandSocketUrl)) {
    const end = commandSocketUrl.indexOf('/webclient');
    return commandSocketUrl.substring(0, end).replace('wss://', '').trim();
  }
  return '';
}

export function getVideoRwcIpAddress({ svcUrl, meetingNumber, conferenceID }) {
  return `wss://${svcUrl}/wc/media/${meetingNumber}?type=v&cid=${conferenceID}`;
}

export function getAudioRwcIpAddress({ svcUrl, meetingNumber, conferenceID }) {
  return `wss://${svcUrl}/wc/media/${meetingNumber}?type=a&cid=${conferenceID}`;
}

export function getAVMeetingParams({
  meetingStatus,
  meetingNumber,
  conferenceID,
  receiveSharingByJPG,
  svcUrl,
}) {
  if (meetingStatus !== 'joined' || !svcUrl) {
    return {};
  }
  const videoRwcIpAddress = getVideoRwcIpAddress({
    svcUrl,
    meetingNumber,
    conferenceID,
  });

  const audioRwcIpAddress = getAudioRwcIpAddress({
    svcUrl,
    meetingNumber,
    conferenceID,
  });

  const sharingRwcIpAddress = `wss://${svcUrl}/wc/media/${meetingNumber}?type=${
    receiveSharingByJPG ? 'j' : 's'
  }&cid=${conferenceID}`;
  return {
    videoRwcIpAddress,
    audioRwcIpAddress,
    sharingRwcIpAddress,
    ...getWorkerPath(),
  };
}

export function isSimulive() {
  return meetingConfig.isSimuliveWebinar;
}

export function isSimulivePhase1() {
  return (
    meetingConfig.isSimuliveWebinar &&
    !meetingConfig.meetingOptions.isEnableSimulivePhase2
  );
}

export function isSupportSimulivePhase2() {
  return meetingConfig.meetingOptions.isEnableSimulivePhase2;
}

export function isSimuliveVideoMode() {
  return isWebinar() && isSimulive();
}

export function isNormalVideoMode() {
  return !isSimuliveVideoMode() && !isUISelfPaintSharing();
}

export function isSupportHardwareAcceleration() {
  if (isMTRAndroid()) return false;
  // sdk will check if Hardware Acceleration is supported.
  // BUT SIMULIVE DO NOT HAVE AVSOCKET
  return !isSimulive();
}

export function isMeetingSupportVoIPOnly() {
  if (meetingConfig.optionAudioType === 'voip' && isSupportAV()) {
    return true;
  }
  return false;
}

export function isMeetingSupportTelAndVoIP() {
  if (meetingConfig.optionAudioType === 'both') {
    return true;
  }
  if (isMeetingSupportVoIPOnly() && !isSupportAV()) {
    return true;
  }
  return false;
}

export function isMeetingSupportTelOnly() {
  if (meetingConfig.optionAudioType === 'telephony') {
    return true;
  }
  return false;
}

export function isMeetingSupportCallout() {
  if (meetingConfig.optionAudioType === 1) {
    return true;
  }
  return false;
}

export function isJoinAudio(user) {
  if (!_.isEmpty(user)) {
    if (
      user.audio === 'phone' ||
      (user.audio === 'computer' &&
        globalVariable.avSocket.getJoinVoIPTimes() > 0)
    ) {
      return true;
    }
  }
  return false;
}

export function isJoinVoIP(user) {
  if (!_.isEmpty(user)) {
    if (
      user.audio === 'computer' &&
      globalVariable.avSocket.getJoinVoIPTimes() > 0
    ) {
      return true;
    }
  }
  return false;
}

export function isJoinByPhone(user) {
  return !_.isEmpty(user) && user.audio === 'phone';
}

export function isJoinByCallMe(user, isDialoutSucces) {
  if (!_.isEmpty(user)) {
    if (user.audio === 'phone' && isDialoutSucces) {
      return true;
    }
  }
  return false;
}

export function shouldRenderNewCanvas() {
  return isSupportAV();
}

export function canConnectAVSocket() {
  return isSupportAV();
}

export function getCountryCode(callOutCountry) {
  if (_.isEmpty(callOutCountry)) return [];
  let countryCode;
  const options = [];

  callOutCountry.forEach((item) => {
    countryCode = {
      value: item.name,
      label: `${item.name} (${item.code})`,
      code: item.code,
      id: item.id,
    };
    options.push(countryCode);
  });
  return orderByCountryCode(options);
}

export function getParticipantItemTabIndex(isMySelf) {
  return isMySelf ? 0 : -1;
}

export const isUISelfPaintSharing = () => !isSupportAV();

export const getAttendeeType = (jid) => {
  if (/^wu_/.test(jid)) {
    return userTypes.WEBINAR_ATTENDEES_WEB;
  }
  if (/^tu_/.test(jid)) {
    return userTypes.WEBINAR_ATTENDEES_PHONE;
  }
  return null;
};

export const isTelephoneBoundAtteendee = (attendee) => {
  return attendee.bindPhoneUid;
};

export function isTSP() {
  const isTSPEnbaled =
    meetingConfig.tsp &&
    /* eslint-disable-next-line no-prototype-builtins */
    meetingConfig.tsp.hasOwnProperty('isTSP') &&
    meetingConfig.tsp.isTSP;
  const hasValidTSPCallinInfo =
    meetingConfig.tsp &&
    /* eslint-disable-next-line no-prototype-builtins */
    meetingConfig.tsp.hasOwnProperty('callInfo') &&
    _.startsWith(meetingConfig.tsp.callInfo, 'MC') &&
    meetingConfig.tsp.callInfo.split(';').length > 1;
  return isTSPEnbaled && hasValidTSPCallinInfo;
}

export function isVOIPSupportPaltformAndBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isOpera = /opera|opr\/[\d]+/.test(userAgent);
  const isEdge = /edge\/(\d+)/.test(userAgent);
  const isChrome =
    !isOpera && !isEdge && /chrome/.test(userAgent) && /webkit/.test(userAgent);
  const isFirefox = /firefox/.test(userAgent);
  const isAndroid = /android/.test(userAgent);
  const isLinux = !isAndroid && /linux (i686|x86_64)/.test(userAgent);
  return isOpera || isChrome || (isLinux && isFirefox);
}

const laplaceReportOnce = getLaplaceReportOnceFunc();

export function hideJoinLoading(isPreview) {
  const wcLoadingNode = document.getElementById('wc-loading');
  if (wcLoadingNode) {
    wcLoadingNode.style.display = 'none';
    laplaceReportOnce(
      { strMsg: 'hideLoading', startTime: parseInt(performance.now()) },
      {
        sampleRate: 0.001, // PV: 10M, 1/1000 => 10000 log / per day
        logLevel: isPreview ? 'true' : 'false',
      },
    );
  }
}

export function showJoinLoading() {
  const wcLoadingNode = document.getElementById('wc-loading');
  if (wcLoadingNode) {
    wcLoadingNode.style.display = '';
  }
}

export function getWebinarUserIdFromJid(jid, meetingNumber) {
  let userId = '';
  if (!_.isEmpty(jid)) {
    const jidPre = `wu_${meetingNumber}_`;
    if (jidPre.startsWith(jidPre)) {
      const newJid = jid.replace(jidPre, '');
      const flag = newJid.indexOf('@');
      if (flag !== -1) {
        const arr = newJid.substring(0, flag).split('#');
        userId = arr[0];
      }
    }
  }
  return userId;
}

export function launchFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}

export function setIsHostAtLocal(bHost) {
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.webClient_isHost,
    bHost,
    storeType.sessionStorage,
  );
}

export function getDefaultIsHostValue() {
  const isHost = easyStore.easyGet(SESSIONSTORAGE_KEYS.webClient_isHost);
  if (!_.isNil(isHost)) {
    return isHost === true;
  }
  return meetingConfig.isHost;
}

export function storeLeaveParticipant(state, message, isFirstWSChannel) {
  const {
    body: { remove },
  } = message;
  const {
    attendeesList: { attendeesList },
    breakoutRoom: {
      attendee: { status },
      mainSessionAttendeeList,
    },
  } = state;
  if (
    isWebinar() ||
    (isFirstWSChannel && isInRoom(status)) ||
    _.isEmpty(remove)
  ) {
    return;
  }
  let allAttendeeListInMeeting = attendeesList;
  if (!isFirstWSChannel && isInRoom(status)) {
    allAttendeeListInMeeting = mainSessionAttendeeList;
  }
  const currentRemoveList = remove.filter((item) => item.nUserStatus !== 1);
  if (currentRemoveList.length === 0) {
    return;
  }
  const allParticipants = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_RemovedParticipants,
  );
  const removeList = [];
  currentRemoveList.forEach((item) => {
    const targetRemoveAttendee = allAttendeeListInMeeting.find(
      (attendee) => attendee.userId === item.id,
    );
    if (targetRemoveAttendee) {
      removeList.push({
        userId: item.id,
        avatar: targetRemoveAttendee.avator,
        userRole: targetRemoveAttendee.userRole,
        bCoHost: targetRemoveAttendee.bCoHost,
        displayName: targetRemoveAttendee.displayName,
        isRemoved: true,
        zoomID: targetRemoveAttendee.zoomID,
        userGUID: targetRemoveAttendee.userGUID,
        isGuest: targetRemoveAttendee.isGuest,
        caps: targetRemoveAttendee.caps,
        bMultiStreamVideoUser: targetRemoveAttendee.bMultiStreamVideoUser,
      });
    }
  });
  if (removeList.length === 0) {
    return;
  }
  let result = '';
  if (allParticipants) {
    let resultArray = [];
    const allParticipantsList = JSON.parse(allParticipants);
    if (_.isArray(allParticipantsList)) {
      allParticipantsList.forEach((user) => {
        // filter user which has been removed
        const targetHasBeenRemovedIndex = removeList.findIndex(
          (item) => item.zoomID === user.zoomID,
        );
        if (targetHasBeenRemovedIndex > -1) {
          removeList.splice(targetHasBeenRemovedIndex, 1);
        }
      });
      resultArray = allParticipantsList.concat(removeList);
      result = JSON.stringify(resultArray);
    }
  } else {
    result = JSON.stringify(removeList);
  }
  if (result) {
    easyStore.easySet(
      SESSIONSTORAGE_KEYS.webClient_RemovedParticipants,
      result,
      storeType.sessionStorage,
    );
  }
}

export function getLeavedParticiapnts() {
  const allParticipants = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_RemovedParticipants,
  );
  if (allParticipants) {
    const allParticipantsList = JSON.parse(allParticipants);
    if (_.isArray(allParticipantsList)) {
      return allParticipantsList;
    }
    return [];
  }
  return [];
}
export const getJoinUrl = (meetingNumber) => {
  let joinURL = '';
  const text = getInviteEmail();
  if (text) {
    joinURL = text
      .split('\n')
      .find(
        (v) => v.indexOf('https') > -1 && v.indexOf(`${meetingNumber}`) > -1,
      );
  }
  return joinURL;
};

export const getNumberBadge = (value, max = 99) => {
  if (value > max) {
    return `${max}+`;
  }
  return value;
};

export function isAskToUnmute(caps) {
  return (caps & PARTICIPANT_NEED_ASK_UNMUTE) === PARTICIPANT_NEED_ASK_UNMUTE;
}

export function canBeAssignedToHugeBo(caps) {
  return (
    (caps & PARTICIPANT_CAN_BE_ASSIGNED_HUGE_BO) ===
    PARTICIPANT_CAN_BE_ASSIGNED_HUGE_BO
  );
}

export function updateOptInStorage(message) {
  const {
    body: { opt },
  } = message;
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.webClient_opt,
    opt,
    storeType.sessionStorage,
  );
}

export function isMicDeviceValid(deviceId, deviceLabel) {
  const preCondiditon =
    isExternalControlledMode() || deviceId !== 'communications';

  return (
    preCondiditon &&
    !/ZoomAudioDevice/i.test(deviceLabel) &&
    !/Zoom-\S*/.test(deviceLabel) &&
    !/CubebAggregateDevice\S*/.test(deviceLabel)
  );
}

export function isSpeakerDeviceValid(deviceId, deviceLabel) {
  const preCondiditon =
    isExternalControlledMode() || deviceId !== 'communications';

  return preCondiditon && !/ZoomAudioDevice/i.test(deviceLabel);
}

export function isChatEnabled() {
  return meetingConfig.meetingOptions.isChatEnabled;
}

export function getFooterHeight() {
  return GLOBAL_FOOTER_TOOLBAR_HEIGHT;
}

export function isEnableHostNeedConfirmRecodingReminder() {
  const {
    recordingReminderForRecorder,
    meetingOptions: { isUserEnableRecordingReminder },
  } = meetingConfig;
  if (recordingReminderForRecorder && isUserEnableRecordingReminder) {
    return recordingReminderForRecorder.isEnableRecordingReminder;
  }
  return false;
}

export function isSupportReport(
  reportDomain,
  boAttendeeStatus = ATTENDEE_STATUS.INITIAL,
) {
  return (
    !!reportDomain &&
    meetingConfig.meetingOptions.isReportParticipantsEnabled &&
    !isWebinar() &&
    !isInRoom(boAttendeeStatus)
  );
}

export function isParticipantSupportReport(
  reportDomain,
  boAttendeeStatus = ATTENDEE_STATUS.INITIAL,
) {
  return (
    isSupportReport(reportDomain, boAttendeeStatus) &&
    meetingConfig.meetingOptions.isAllowParticipantsReport
  );
}

export function isFirstEnter() {
  return !easyStore.easyGet(LOCALSTORAGE_KEYS.webClient_first_enter_flag);
}

export function isShowUpdateBrowserTips() {
  if (
    isChromeOS() ||
    isExternalControlledMode() ||
    isOculus() ||
    isSupportMultiView()
  ) {
    return false;
  }
  return showBrowserUpgradeBanner();
}

export function isShowNewFeatureTips() {
  return isFirstEnter() && isSupportMultiView();
}

export function getRecordingStatus(bRecord, bPause) {
  if (typeof bRecord !== 'undefined' || typeof bPause !== 'undefined') {
    if (bPause) {
      return RECORDING_STATUS.PAUSE;
    }
    if (bRecord) {
      return RECORDING_STATUS.RECORDING;
    }
    return RECORDING_STATUS.STOP;
  }
  return undefined;
}
export function transformRecordingStatus(cmrServerStatus) {
  switch (cmrServerStatus) {
    case CMR_RECORDING_STATUS.INIT:
      return RECORDING_STATUS.INIT;
    case CMR_RECORDING_STATUS.START:
      return RECORDING_STATUS.RECORDING;
    case CMR_RECORDING_STATUS.PAUSE:
      return RECORDING_STATUS.PAUSE;
    case CMR_RECORDING_STATUS.STOP:
      return RECORDING_STATUS.STOP;
    default:
      return RECORDING_STATUS.STOP;
  }
}
export function isRecordingPaused(bRecord) {
  return bRecord === RECORDING_STATUS.PAUSE;
}
export function isRecordingStoped(bRecord) {
  return bRecord === RECORDING_STATUS.STOP;
}
export function isRecordingInProgress(bRecord) {
  return bRecord === RECORDING_STATUS.RECORDING;
}
export function isRecordingInInit(bRecord) {
  return bRecord === RECORDING_STATUS.INIT;
}
export function isCMRRecordingSuccess(cmrServerStatus) {
  return cmrServerStatus === CMR_RECORDING_STATUS.START;
}
export function isCMRRecordingInit(cmrServerStatus) {
  return cmrServerStatus === CMR_RECORDING_STATUS.INIT;
}

export function isOnZoomMeeting() {
  return meetingConfig.onZoomOptions && meetingConfig.onZoomOptions.isOnZoom;
}
export function isDisableInviteForOnZoom() {
  return isOnZoomMeeting() && meetingConfig.onZoomOptions.disableInvite;
}

export function isShowBackground(user) {
  if (isMTRAndroid()) {
    return false;
  }
  if (!isSupportAV()) {
    return false;
  }

  if (isSimulive()) {
    return false;
  }

  if (isWebinar() && isViewOnly(user.userRole)) {
    return false;
  }

  if (!isSupportVB()) {
    return false;
  }

  return true;
}

export function isShowVideoSetting() {
  if (!isSupportAV()) {
    return false;
  }
  if (isSimulive()) {
    return false;
  }
  return true;
}

export function isShowAudioSetting() {
  if (!isSupportAV()) {
    return false;
  }
  return true;
}

export const cacheMainSessionEncryptKey = (
  encryptKeyType,
  { encryptKey, additionalType },
  isInBO,
) => {
  if (!isInBO) {
    easyStore.easySet(
      encryptKeyType,
      {
        encryptKey,
        additionalType,
      },
      storeType.sessionStorage,
    );
  }
};

export const getMainSessionEncryptKey = (encryptKeyType) => {
  return easyStore.easyGet(encryptKeyType);
};

export const isZRUserByCaps = (caps) =>
  !!(caps & CAPS_OPTION_SUPPORT_ZOOM_PRESENCE);

export const isZRMultiStreamVideoUser = ({ caps, bMultiStreamVideoUser }) => {
  return isZRUserByCaps(caps) && bMultiStreamVideoUser !== undefined;
};

export const isZRMultiStreamVideoParentUser = ({
  caps,
  bMultiStreamVideoUser,
}) => {
  return (
    isZRMultiStreamVideoUser({ caps, bMultiStreamVideoUser }) &&
    !bMultiStreamVideoUser
  );
};

export const isZRMultiStreamVideoChildUser = ({
  caps,
  bMultiStreamVideoUser,
}) => {
  return (
    isZRMultiStreamVideoUser({ caps, bMultiStreamVideoUser }) &&
    bMultiStreamVideoUser
  );
};

export const filterZRChildUser = (attendeesList, participant) => {
  if (!isZRMultiStreamVideoChildUser(participant)) return false;
  const parent = attendeesList.find(
    (user) => user.userId === participant.parentUserID,
  );
  return parent ? parent.zrCollapse : true;
};

export function getWaterMarkText() {
  return meetingConfig.userEmail || easyStore.easyGet('sessionUserName');
}
/**
 * refactor it in next version. this should be a selector
 * @warn you need to use `isPollingEnabledSelector`
 */
export function isPollEnabled({
  coOrHost,
  currentPollNState,
  isRwgEnablePolling,
  hasPollingInMeeting,
  isMeInRoom,
  canCurrentUserAccessPollsOnWeb,
}) {
  if (isTeslaMode()) {
    return false;
  }
  if (isMeInRoom) {
    return false;
  }
  if (isWebinar()) {
    if (
      typeof meetingConfig.meetingOptions.isWebinarPollingEnabled !==
      'undefined'
    ) {
      if (meetingConfig.meetingOptions.isWebinarPollingEnabled !== true) {
        return false;
      }
    }
    if (
      currentPollNState === PollItemState.ZoomPollState_Open_launching ||
      currentPollNState === PollItemState.ZoomPollState_ShareResult_sharing
    ) {
      return true;
    }
    if (coOrHost) {
      return true;
    }
    return false;
  }
  if (
    !isWebinar() &&
    isRwgEnablePolling &&
    meetingConfig.meetingOptions.isPollingEnabled
  ) {
    if (
      currentPollNState === PollItemState.ZoomPollState_Open_launching ||
      currentPollNState === PollItemState.ZoomPollState_ShareResult_sharing
    ) {
      return true;
    }
    if (hasPollingInMeeting && coOrHost) {
      return true;
    }
    if (!hasPollingInMeeting && canCurrentUserAccessPollsOnWeb && coOrHost) {
      return true;
    }
    return false;
  }
  return false;
}

export function isSupportVB() {
  const isEnableWebclientVB =
    meetingConfig.meetingOptions.isEnableWebclientVB ?? true;
  if (!isEnableWebclientVB) {
    return false;
  }

  if (isExternalControlledMode()) {
    return false;
  }

  if (isMobileDevice()) {
    return false;
  }

  if (!navigator.hardwareConcurrency || navigator.hardwareConcurrency <= 2) {
    return false;
  }

  if (!JsMediaSDK_Instance.util.isChromeVersionHigherThan) {
    return false;
  }

  if (
    !JsMediaSDK_Instance.util.isChromeVersionHigherThan(91) &&
    !JsMediaSDK_Instance.util.isFirefoxVersionHigherThan(89) &&
    !JsMediaSDK_Instance.util.isSafariVersionHigherThan(17.4)
  ) {
    return false;
  }

  if (isFirefox() && !isEnableVBForFirefox()) return false;

  if (
    typeof OffscreenCanvas === 'function' &&
    typeof requestAnimationFrame === 'function' &&
    typeof SharedArrayBuffer === 'function'
  ) {
    return true;
  }

  return false;
}

export const relativeWindowHeight = (height) => {
  return height;
};

export const relativeWindowWidth = (width) => {
  return width;
};

export function isShouldDisableMessageOnTeslaMode(eventType) {
  if (!isTeslaMode()) {
    return false;
  }
  const disableMessages = Object.values(DISABLE_MESSAGE_TYPES_ON_TESLA);
  if (disableMessages.includes(eventType)) {
    return true;
  }
  return false;
}

export const getStartText = (text, tagName = 'a') => {
  const tempText = text.replace(/\s+/g, '$');
  const reg = new RegExp(`(\\S*)<${tagName}>`);
  let startText = reg.exec(tempText)?.[1];
  // let startText = tempText.match(/(\S*)<a>/)?.[1];
  if (startText) {
    startText = startText.replace(/\$/g, ' ');
    return startText;
  }

  return text;
};

export const getMiddleText = (text, tagName = 'a') => {
  const tempText = text.replace(/\s+/g, '$');
  const reg = new RegExp(`<${tagName}>([\\s\\S]*?)<\\/${tagName}>`);
  let middleText = reg.exec(tempText)?.[1];
  // let middleText = tempText.match(/<a>([\s\S]*?)<\/a>/)?.[1];
  if (middleText) {
    middleText = middleText.replace(/\$/g, ' ');
    return middleText;
  }

  return text;
};

export const getEndText = (text, tagName = 'a') => {
  const tempText = text.replace(/\s+/g, '$');
  const reg = new RegExp(`<${tagName}>([\\s\\S]*?)<\\/${tagName}>(\\S*)`);
  let endText = reg.exec(tempText)?.[2];
  // let endText = tempText.match(/<a>([\s\S]*?)<\/a>(\S*)/)?.[2];
  if (endText) {
    endText = endText.replace(/\$/g, ' ');
    return endText;
  }

  return text;
};

export const isRecurringMeeting = () => {
  return meetingConfig.meetingOptions.isRecurringMeetingExceptRepeat;
};

export const isNewJoinFlowEnabled = () => {
  return meetingConfig.isNewJoinFlow;
};

export const isSinglePageFlowEnabled = () => {
  return meetingConfig.joinFlowPhase3;
};

// remove po in query string to avoid previewOptions reset after page refresh, see ZOOM-352317
export const removePOInQuery = () => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (!_.isNil(query.po)) {
    delete query.po;
    history.replaceState(
      null,
      '',
      `${location.pathname}?${qs.stringify(query)}`,
    );
  }
};
export function isAddNewUser(user) {
  return user.action === undefined; // rwg canceled the type USER_ACTION_TYPE_NEW_USER
}

export function isUserFromWaitingRoom(user) {
  return (
    user.action === 2 &&
    !user.bHold &&
    user.nUserStatus === USER_STATUS_FAILOVER
  );
}

export function isRemoveUser(user) {
  // the failover user should not play chime when he leaves
  return user.nUserStatus !== USER_STATUS_FAILOVER;
}
// #region PMC newChat releated
export function isEnablePMC() {
  return meetingConfig.meetingOptions.isEnablePersistentMeetingChat;
}

export const getUserFullName = (function getUserFullNameWrapper() {
  let fullName;
  let hasGetName = false;
  return () => {
    if (!hasGetName) {
      fullName = [meetingConfig.firstName, meetingConfig.lastName]
        .filter(Boolean)
        .join(' ');
    }
    return fullName;
  };
})();

/*
newMeetingChatExperience: {
    "deleteMsgInMeetingChat": false,
    "screenshotInMeetingChat": false,
    "editMsgInMeetingChat": false,
    "allowNewMeetingChatExperience": false,
    "ReactionInMeetingChat": false
}
*/
// new chat enable for meeting level
export function isNewChatWebSettingEnable() {
  if (_.isObject(meetingConfig.meetingOptions.newMeetingChatExperience)) {
    return meetingConfig.meetingOptions.newMeetingChatExperience
      ?.allowNewMeetingChatExperience;
  }
  return false;
}

export function isNewChatUIReady(isInBo = false) {
  if (isWebinar()) return false;
  if (isEnablePMC() && !isInBo) return true;
  return isNewChatWebSettingEnable();
}

export function isNewChatEnableDelete() {
  return (
    isNewChatUIReady() &&
    meetingConfig.meetingOptions.newMeetingChatExperience
      ?.deleteMsgInMeetingChat
  );
}

export function isNewChatEnableEdit() {
  return (
    isNewChatUIReady() &&
    meetingConfig.meetingOptions.newMeetingChatExperience?.editMsgInMeetingChat
  );
}

export function isNewChatEnableReaction() {
  if (isEnablePMC()) return true;
  return (
    isNewChatUIReady() &&
    meetingConfig.meetingOptions.newMeetingChatExperience
      ?.ReactionInMeetingChat &&
    isNewChatEnableReplyReactionInOp()
  );
}

export function isNewChatEnableReply() {
  if (isEnablePMC()) return true;
  return isNewChatEnableReplyReactionInOp();
}
/**
 *
 * v5.12.6, op flag default OFF
 * op flag = OFF isEnableMeetingChatReaction = false
 * PMC display reply & reaction
 * normal meeting hide  reply & reaction, web setting also hide switch
 * op flag = ON isEnableMeetingChatReaction = true
 * PMC show reply & reaction
 * normal meeting reply，reaction follow web setting(new chat experience - reaction)
 *
 * result: PMC always show reply & reaction
 * if flag off, normal meeting hide reply & reaction, otherwise follow web setting
 */
export function isNewChatEnableReplyReactionInOp() {
  const { isEnableMeetingChatReaction } = meetingConfig.meetingOptions || {};
  if (typeof isEnableMeetingChatReaction === 'undefined') {
    return true;
  }
  return isEnableMeetingChatReaction;
}
export function getMeetingChannelId() {
  return meetingConfig.meetingOptions.channelId;
}

export function getUserXmppJidRemoveMN(jid = '', meetingNumber) {
  const prefixReg = new RegExp(`^\\w\\w_${meetingNumber}_`);
  return jid.replace(prefixReg, '');
}

export function isBlockedJoinMeeting() {
  if (!meetingConfig.meetingForceBreak) {
    return false;
  }
  return meetingConfig.meetingForceBreak.errorCode === 13210;
}

export function getFirstWord(str) {
  let index = str.indexOf(' ');
  if (index === -1) return '';
  return str.substring(0, index);
}

export function getLastWord(str) {
  let index = str.lastIndexOf(' ');
  if (index === -1 || index === str.length - 1) return '';
  return str.substring(index + 1);
}

// #endregion
export const checkIsHostSupportGallerySortType = (caps) => {
  return caps && !!(caps & CAPS_OPTION_SUPPORT_GALLERY_VIEW_SORT_TYPE);
};

export const isSupportGallerySortMode = () => {
  const { enableSortGalleryView } = meetingConfig.meetingOptions || {};
  return !!enableSortGalleryView;
};

export function getInviteEmail() {
  const emailEl = document.getElementById('invite_email');
  if (emailEl && emailEl.value) {
    return emailEl.value;
  } else {
    return meetingConfig.invite_email;
  }
}

export function getThirdPartyAudioInfo() {
  const infoEl = document.getElementById('3rd_party_audio_info');
  if (infoEl && infoEl.value) {
    return infoEl.value;
  } else {
    return meetingConfig.otherTelInfo;
  }
}

export function isEnablePMCFileSync() {
  //  ispmc && account in accountids_enable_pmc_file_sync
  return meetingConfig.meetingOptions.enablePmcFileSync;
}

// not used now, could support later
// it's default off on env for a long time
export function isDisableFileUploadCipher() {
  // ispmc && account in accountids_upload_file_not_cipher_in_meeting
  return meetingConfig.meetingOptions.disableUploadFileCipher;
}

export const isSupportAudioDenoise = () => {
  const opFlag = isEnableAudioDenoiseFlag();
  const performanceSupport =
    JsMediaSDK_Instance?.util?.isSupportAudioDenoise?.() ?? false;
  return opFlag && performanceSupport;
};

export const isSupportSharingStereo = () => {
  return JsMediaSDK_Instance.util.isSupportSharingStereo?.() ?? false;
};

export const getCookieDomain = () => {
  const domain = location.host.match(/[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/)[0];
  return `.${domain}`;
};

export const isEnableOneChatUI = () => {
  // return false;
  return isNewChatWebSettingEnable() && isSwitchToOneChatList() && !isWebinar();
};

export const isHostEnableGiphy = () => {
  return (
    meetingConfig.meetingOptions.newMeetingChatExperience.giphyInMeetingChat &&
    meetingConfig.isLogin
  );
};

export const getMeetingGiphyRating = () => {
  try {
    return meetingConfig.meetingOptions.newMeetingChatExperience
      ?.giphyRatingInMeetingChat;
  } catch (error) {
    return 'G';
  }
};
export const isSDKSupportLocalLiveStream = (caps) =>
  !!(caps & CAPS_OPTION_SUPPORT_LOCAL_LIVESTREAMING);

export const isSupportWaitingRoomWithoutFailoverFlow = () =>
  JsMediaSDK_Instance.util?.isSupportNewWaitRoomFlow?.() &&
  !isAudioBridge() &&
  isEnableWaitingRoomJoinWithoutFailoverFlow() &&
  !getFinalUseWebRTC();

export const getWebMediaBlockConfig = () => meetingConfig.webMediaBlockConfig;

export const shouldShowOpenSystemSettings = () => {
  return isWindows() || isMac();
};
