import { preloadWebClient } from '../../preloadWebClient';
import { loadWebclient } from '../../loadWebclient';
import { initLogManager } from '../../initLogManager';
import { handleRouter } from '../../handleRouter';
import { initDeviceManager } from './initDeviceManager';
import { initTP } from '../../../global/service/tp-service';
import { blackBoxProblemDetection } from './black-box-problem-detection';

export const taskBeforeLoadWebClient = [
  blackBoxProblemDetection,
  initDeviceManager,
  handleRouter,
  preloadWebClient,
  initLogManager,
  initTP,
  loadWebclient,
];
