export const PREVIEW_EVENT = {
  AUDIO_CONNECT_SUCCESS: 'audio_connect_success',
  AUDIO_INIT_SUCCESS: 'audio_init_success',
  AUDIO_PREVIEW_ASN: 'audio_preview_asn',
  AUDIO_LEAVE_SUCCESS: 'audio_leave_success',
  AUDIO_FORBIDDEN: 'audio_forbidden',

  VIDEO_INIT_SUCCESS: 'video_init_success',
  VIDEO_START_SUCCESS: 'video_start_success',
  CAMERA_IS_TAKEN: 'camera_is_taken',
  VIDEO_FORBIDDEN: 'video_forbidden',
  REQUEST_PERMISSION_RESULT: 'request_permission_result',
  REQUEST_PERMISSION_POP: 'request_permission_pop',
};

export const PREVIEW_AUDIO_STATUS = {
  DISABLED: 'disabled',
  FORBIDDEN: 'forbidden',
  NOT_CONNECTED: 'not-connected',
  MUTED: 'muted',
  UNMUTED: 'unmuted',
  LOADING: 'loading',
  CAPTURING: 'capturing',
};

export const PREVIEW_VIDEO_STATUS = {
  DISABLED: 'disabled',
  FORBIDDEN: 'forbidden',
  CAN_NOT_DETECT_CAMERA: 'can_not_detect_camera',
  OPEN: 'open',
  CLOSED: 'closed',
  LOADING: 'loading',
  CAPTURING: 'capturing',
};

export const PREVIEW_CANVAS = 'canvas-preview';

export const PREVIEW_ERROR = {
  NONE: 'none',
  CAMERA_TAKEN: 'camera_taken',
  AUDIO_FORBIDDEN: 'audio_forbidden',
  VIDEO_FORBIDDEN: 'video_forbidden',
  CAN_NOT_DETECT_CAMERA: 'can_not_detect_camera',
};

export const PREVIEW_SIZE = {
  SMALL: 'small',
  LARGE: 'large',
  TESLA: 'tesla',
};

export const PREVIEW_CANVAS_SIZE = {
  [PREVIEW_SIZE.SMALL]: {
    WIDTH: 254,
    HEIGHT: 143,
  },
  [PREVIEW_SIZE.LARGE]: {
    WIDTH: 700,
    HEIGHT: 395,
  },
  [PREVIEW_SIZE.TESLA]: {
    WIDTH: 600,
    HEIGHT: 370,
  },
};

export const FETCH_MEETING_STATUS_INTERVAL = 30 * 1000; //30s

export const ERROR_CODE = {
  webinar_in_practice_session: 3129,
  join_wrong_password: 3004,
  join_need_name: 9503,
  join_need_email: 9504,
  register_info_invalid: 9506,
  client_uuid_blocked: 1132,
  webclient_post_captcha: 9505,
  webclient_post_invisible_captcha: 9515,
};

export const MEETING_INFO_TEXT_LIMIT = 128;

export const CACHE_KEY = {
  PREVIEW_OPTIONS: 'previewOptions',
  AVSTATUS: 'avStatus',
};

export const COOKIE_REMEMBERED_NAME = '_zm_wc_remembered_name';

export const EXPIRE_DAYS_REMEMBERED_NAME = 90;

export const PRIVACY_NOTIFICATION_LINK =
  'https://support.zoom.us/hc/en-us/articles/360059564372-In-Product-Privacy-Notifications';

export const NO_PERMISSION_HELP_LINK =
  'https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0078269';

export const BG_IMG_ID = 'preview-background-img-id';

// default connect and open audio/video
export const DEFAULT_PREVIEW_OPTIONS = 7;

export const REQUEST_PERMISSION_STATUS = {
  GRANTED_AUDIO_VIDEO: 1,
  GRANTED_AUDIO: 2,
  DENIED: 3,
  EXCEPTION_FAILS: 4,
  DISMISS: 5,
};
