import mitt from '../utils/mitt';

export const emitter = mitt();
let controller = new AbortController();
let signal = controller.signal;

// Add an event listener to the signal
const watchAbort = (cb) => {
  const onAbort = () => {
    // eslint-disable-next-line no-unused-vars
    const ret = cb();
    signal.removeEventListener('abort', onAbort);
  };
  signal.addEventListener('abort', onAbort);
  return () => {
    signal.removeEventListener('abort', onAbort);
  };
};

let eventHistory = {};

export const cancelBlackBoxJob = () => {
  eventHistory = {};
  controller.abort();
  setTimeout(() => {
    controller = new AbortController();
    signal = controller.signal;
  }, 0);
};

export const blackBoxEmitEvent = (event, payload) => {
  eventHistory[event] = payload ?? event;
  emitter.emit(event, payload);
};

export const blackBoxListenEvent = (event, callback) => {
  emitter.on(event, callback);
};

const getEventHistory = (event) => {
  return eventHistory[event];
};
const debugBlackBoxLog = (log) => {
  if (CLIENT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(log, 'black_box');
  }
};
export const expectEvent = (...events) => {
  const waitForEvent = (event, context) => {
    return new Promise((resolve, reject) => {
      const removeAbort = watchAbort(() => {
        reject(`canceled_event ${event}`);
        return event;
      });
      const historyPayload = getEventHistory(event);
      if (historyPayload !== undefined) {
        debugBlackBoxLog(`<----get event, existed: ${event}`);
        removeAbort();
        resolve({ event, payload: historyPayload });
      } else {
        let timeoutHandler;
        const timeoutF = (payload) => {
          clearTimeout(timeoutHandler);
          emitter.off(event, timeoutF);
          debugBlackBoxLog(`<----get event: ${event}`);
          removeAbort();
          resolve({ event, payload });
        };
        if (context.timeout) {
          timeoutHandler = setTimeout(() => {
            emitter.off(event, timeoutF);
            removeAbort();
            if (context.canceled) {
              resolve(`canceled_event ${event}`);
            } else {
              debugBlackBoxLog(`${event} timeout ${context.timeout}`);
              reject(`${event} timeout ${context.timeout}`);
            }
          }, context.timeout);
        }
        emitter.on(event, timeoutF);
      }
    });
  };

  return {
    wait: async (timeout = 0) => {
      debugBlackBoxLog(
        `->wait(${timeout ? timeout : 'until'}): ${events.join('\n')}`,
      );
      const that = { canceled: false, timeout };
      const promises = events.map((event) => waitForEvent(event, that));
      return Promise.any(promises)
        .then((v) => {
          that.canceled = true;
          return v;
        })
        .catch((v) => {
          throw new Error(v.errors.join(' | '));
        }); // Wait for any one event to resolve
    },
  };
};

export const BlackBoxEvent = {
  start_entry_js: 'start_entry_js',
  just_start_meeting: 'just_start_meeting',
  just_start_preview: 'just_start_preview',
  skip_preview_to_start_meeting: 'skip_preview_to_start_meeting',
  skip_preview_to_start_JBH: 'skip_preview_to_start_JBH',
  preview_to_JBH: 'preview_to_JBH',
  join_meeting_from_JBH: 'join_meeting_from_JBH',
  join_meeting_from_preview: 'join_meeting_from_preview',
  audio_ready_to_use: 'audio_ready_to_use',
  start_joining_audio: 'start_joining_audio',
  join_audio_success: 'join_audio_success',
  join_audio_timeout: 'join_audio_timeout',
  video_usable: 'video_usable',
  video_initial_ready: 'video_initial_ready',
  start_opening_video: 'start_opening_video',
  open_video_success: 'open_video_success',
  reset: 'reset',
  inMeeting_hideLoading: 'inMeeting_hideLoading',
  preview_hideLoading: 'preview_hideLoading',
  reset_by_fail_over: 'reset_by_fail_over',
};
