import React from 'react';
import zlib from 'browserify-zlib';
import {
  setCreateBoRoomsWindowVisible,
  setManageBoRoomsWindowVisible,
  setRequestJoinBoRoomDialogVisible,
  addRoom,
  resetRoomLocalId,
  resetAllRooms,
  setControlStatus,
  setWhereJoinRoomPopoverTipVisible,
  setOptionsValueAutojoin,
  setOptionsValueBacktomainsession,
  setOptionsValueAutoclosetimer,
  setOptionsValueTimerduration,
  setOptionsValueTimerautoend,
  setOptionsValueNeedcountdown,
  setOptionsValueWaitseconds,
  updateRooms,
  setAttendeeRoomInfo,
  setInviteJoinBoRoomDialogVisible,
  setRoomLocalId,
  setAttendeeRoomStatus,
  setRoomWillCloseDialogVisible,
  setAutoLeaveBoMeetingTimer,
  setBroadcastMessage,
  setRoomStartedTime,
  addMainSessionAttendeeList,
  updateMainSessionAttendeeList,
  removeMainSessionAttendeeList,
  setJoinBoCmd,
  setAskForHelpDialogVisible,
  setNeedHelpDialogVisible,
  setWhenCloseBoStarttime,
  setSecondWsChannelReady,
  setIsHostBeforeJoinBo,
  setJoinBoConfId,
  setHasReceiveBoClosingRes,
  removeBoAssignedUserGuid,
  setPreRoomSize,
  setBoLoading,
  setPreAssignBoList,
  setBoRoomsHasCreated,
  setOptionsValueParticipantsChooseRoom,
  setSelfSelectHasStartedPopoverVisible,
  setPreAssignmentEnabled,
  assignAttendeeIntoBoRoom,
  setHasReceivedPreAssignRes,
  addPreAssignmentToAssignDict,
  incProtoCount,
  setReturnToMainSessionDialogVisible,
  setLatestInviter,
  setWhoStop,
  setWhoStart,
  setJoinReason,
  setTimeUpNotifyDialogVisible,
  setIsAddRoomWaitingBoAttributeIndication,
  setSaveBoSuccessVisible,
  setHasEverBeenPreAssignedDict,
  updateBreakoutRoomActivityStatusByUser,
  setIsBroadcastVoice,
  setIsRecvBroadcastVoice,
  setMainSessionAudioEncryptInfo,
  setMainSessionShareEncryptInfo,
  setBoRoomsDistributionPattern,
  setOptionsValueAutomovetomainsession,
} from './bo-room-action';
import {
  unassignedAttendeeListSeletor,
  isCoHostInBoSelector,
  currentUserCanSelectRoomSelector,
  currentUserIsAbleToManageBoSelector,
  hostAttendeeForBreakoutRoomSelector,
  inviterNameSelector,
  isBroadcastVoiceButMutedSelector,
} from './bo-room-selector';
import {
  ROOMS_DISTRIBUTION_PATTERN,
  CONTROL_STATUS,
  BO_COMMAND_TYPES,
  ATTENDEE_STATUS,
  BO_ROOM_STATUS,
  BO_HOST_HELP_REQ_TYPES,
  BO_JOIN_METHODS,
  BO_JOIN_REASONS,
  BO_ROOM_CREATE_WAY_WHILE_START,
  SAVE_BO_ERROR_TYPES,
} from '../constant';
import {
  closeSocket,
  saveWebSDKUserThunk,
  sendSocketMessage,
} from '../../../actions/SocketActions';
import {
  BREAKOUTROOM_DEFAULT_ROOM_NAME,
  BREAKOUTROOMS_INVITE_HOST_SUCCESS_TIP,
  BREAKOUTROOMS_INVITE_HOST_FAILED_TIP,
  BREAKOUTROOMS_INVITE_HOST_SUCCESS_TIP2,
  CLOSE_BO_CONFIRM_CONTENT1,
  CLOSE_BO_CONFIRM_CONTENT2,
  CLOSE_BO_CONFIRM_TITLE,
  BREAKOUTROOMS_CLOSE_ALL_ROOMS,
  UNABLE_SAVE_BO_TEXT,
  UNABLE_SAVE_BO_ERROR2,
  UNABLE_SAVE_BO_ERROR_TIP,
  UNABLE_SAVE_BO_ERROR_TIP_PLACEHOLDER,
  UNABLE_SAVE_BO_ERROR1,
  UNABLE_SAVE_BO_COMMON_ERROR,
  BREAKOUTROOM_INVITE_JOIN_ROOM_TIP_DEFAULT_HOST,
  SAVE_BO_DUPLICATE_BO_NAME_ERROR_DIALOG_TEXT,
} from '../resource';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import {
  isRoomNotStarted,
  isNotInRoom,
  clearUserJoinBoInfoInStorage,
  isRoomInProgress,
  isBeInvited,
  isJoiningRoom,
  isInRoom,
  changeRoomDataFromServerToLocal,
  isReturningToMainSession,
  isMeShouldJoinBo,
  isRoomClosed,
  isRoomClosing,
  boAttributeIndicationAdapter,
  setUserReceiveJoinBoCmdStorage,
  clearUserReceiveJoinBoCmdStorage,
  clearBCoHostInStorage,
  getMaxRoomSize,
  parseBOPreAssignData,
  getUserGuidsFromUserEmails,
  logHostNotFound,
} from '../utils';
import {
  getStartBoRequestBodyProto,
  getRoomAttendeeIds,
  checkFailoverAttendeeBoStatus,
  readyToJoinBo,
  updateAttendeesList,
  hostReadyToJoinBo,
  changeToReturnedStatus,
  handleMainSessionRes,
  updateMeetingAttrInBo,
  batchCreateRooms,
  updateSharingInfoFromMainSession,
  stopSharingFromMainSession,
} from './bo-room-thunk-service';
import { removeDuplicates } from '../../../reducers/AttendeesListReducer';
import {
  setModerator,
  coHostChange,
  getHostChangeRes,
} from '../../../actions/MeetingActions';
import { wcToast } from '../../../global/components/widget/toast/wc-toast';
import {
  setIsHostAtLocal,
  promptA11yInfo,
  SESSIONSTORAGE_KEYS,
  storeLeaveParticipant,
  updateOptInStorage,
  isExternalControlledMode,
  ELEMENT_PLACEHOLDER,
  isZRMultiStreamVideoUser,
} from '../../../global';
import { currentRoleSelector } from '../../../global/redux/selector';
import { beginDecrypt, ivType, sdkIvTypeKeyEnum } from '../../../global/crypto';
import { addRoomDebounce } from '../a11y/utils';
import {
  addDraftRoom,
  updateDraftRooms,
  getDraftRoom,
  clearDraftRooms,
} from '../cache';
import * as meetingActions from '../../../actions/MeetingActions';
import {
  externalController,
  CONTROL_MODE_ZOOM_MSG_TYPE,
} from '../../../controller';
import {
  receiveSharingChannelReady,
  receiveSharingChannelClose,
  resetIsSharingToBO,
  restoreShareAudioStatus,
} from '../../sharing/redux/sharing-thunk-action';
import { updateUserRosterInfoToSDK } from '../../sharing/service';
import Modal from '../../../global/components/widget/modal';
import { DIALOG_CANCEL, DIALOG_OK } from '../../dialog/resource';
import { toggleInstanceOpen } from '../../dialog/redux/dialog-action';
import { WcLink } from '../../../components/utils/link2';
import reactStringReplace from '../../../global/resct-string-replace';
import { PWAMeetingEvent, sendMsgToPWA } from '../../../global/pwa-integration';
import { clearAllFileMessage } from '../../chat/redux/chat-thunk-action';
import { getAsn } from '../../audio/redux/audio-thunk-action';
import * as AVNotifyMediaSDKTypes from '../../../constants/AVNotifyMediaSDKTypes';
import { globalVariable } from '../../../global/global-variable';
import { clearNewChatAllFileMessage } from '../../new-chat/redux/new-chat-file-thunk';
import { easyStore, storeType } from '../../../global/easy-store';
import { updateOtherNetworkQuality } from '../../video/redux/thunks/network-quality-thunk';
import { instantDialogRef } from '../../dialog/service/command-dialog';
import { clearAllOneChatFileMessage } from '../../../global/ChatSDK/redux/file-thunk';
import AliveToast from '../../../global/containers/notification-manager/alive-toast';
import { MUTED_TOAST, UNMUTE_TEXT } from '../../audio/resource';
import ZoomButton from '../../../global/components/widget/button/zoom-button';
import { handleAudioButtonClickThunk } from '../../audio/redux/thunks/handle-audio-button-click-thunk';

export const handleBreakoutRoomsButton = () => (dispatch, getState) => {
  const state = getState();
  const currentUserCanSelectRoom = currentUserCanSelectRoomSelector(state);
  const currentUserIsAbleToManageBo =
    currentUserIsAbleToManageBoSelector(state);
  const inviterName = inviterNameSelector(state);
  const {
    meeting: {
      meetingOptions: { isAllowBreakoutRoomPreAssign },
      currentUser: { bCoHost },
    },
    breakoutRoom: {
      UI: { showWhereJoinRoomPopoverTip, showSelfSelectHasStartedPopover },
      hasCreatedRoom,
      controlStatus,
      attendee: { status, room },
      roomList,
    },
  } = state;
  const isCoHostInBo = isCoHostInBoSelector(state);
  const isBeInviteStatus = isBeInvited(status);
  if (currentUserIsAbleToManageBo) {
    if (
      isAllowBreakoutRoomPreAssign &&
      !hasCreatedRoom &&
      roomList.length === 0
    ) {
      // when host click the Breakout Room first time,and if the meeting has't started bo
      // we need loadPreAssignRooms
      dispatch(setBoRoomsHasCreated(true));
      dispatch(setManageBoRoomsWindowVisible(true));
    } else if (
      !hasCreatedRoom &&
      isRoomNotStarted(controlStatus) &&
      roomList.length === 0
    ) {
      dispatch(setCreateBoRoomsWindowVisible(true));
    } else {
      dispatch(setManageBoRoomsWindowVisible(true));
    }
  } else if (bCoHost) {
    dispatch(setManageBoRoomsWindowVisible(true));
  } else if (isCoHostInBo) {
    dispatch(setManageBoRoomsWindowVisible(true));
  } else if (currentUserCanSelectRoom) {
    dispatch(setManageBoRoomsWindowVisible(true));
  } else if (isBeInviteStatus) {
    dispatch(setRequestJoinBoRoomDialogVisible(true));
    sendMsgToPWA(PWAMeetingEvent.INVITED_TO_BO, {
      hostName: inviterName,
      roomName: room.name,
    });
  }
  if (showWhereJoinRoomPopoverTip) {
    dispatch(setWhereJoinRoomPopoverTipVisible(false));
  }
  if (showSelfSelectHasStartedPopover) {
    dispatch(setSelfSelectHasStartedPopoverVisible(false));
  }
};

/**
 *
 * @param {*} roomSize
 * @param {*} isBatches can be roomName:string when is not batches
 * @returns
 */
export const addRoomsRequest =
  (roomSize, roomNames = [''], isBatches) =>
  (dispatch, getState) => {
    const {
      breakoutRoom: {
        roomNextId,
        roomList,
        hugeBo,
        distributionPattern,
        isIncludeCohostChecked,
      },
    } = getState();

    if (roomList.length + roomSize > getMaxRoomSize(hugeBo)) {
      return false;
    }
    const rooms = [];
    if (isBatches) {
      let unassignedAttendeeList = unassignedAttendeeListSeletor(getState());
      if (!isIncludeCohostChecked) {
        unassignedAttendeeList = unassignedAttendeeList.filter(
          (user) => !user.bCoHost,
        );
      }
      for (let i = 0; i < roomSize; i++) {
        let attendeeIdList = [];
        const roomName =
          roomNames[i] || `${BREAKOUTROOM_DEFAULT_ROOM_NAME} ${roomNextId + i}`;
        if (distributionPattern === ROOMS_DISTRIBUTION_PATTERN.auto) {
          attendeeIdList = getRoomAttendeeIds(
            unassignedAttendeeList,
            roomSize,
            i,
          );
        }
        rooms.push({
          topic: roomName,
          index: i,
        });
        addDraftRoom({
          name: roomName,
          requestId: i,
          attendeeIdList,
        });
      }
      dispatch(setRoomLocalId(roomList.length + roomSize));
    } else {
      const roomName =
        roomNames[0] || `${BREAKOUTROOM_DEFAULT_ROOM_NAME} ${roomNextId + 1}`;
      rooms.push({
        topic: roomName,
        index: 0,
      });
      addDraftRoom({
        name: roomName,
        requestId: 0,
        attendeeIdList: [],
      });
      dispatch(setRoomLocalId(roomNextId + 1));
    }

    const data = {
      evt: socketEventTypes.WS_CONF_BO_TOKEN_BATCH_REQ,
      body: rooms,
    };
    dispatch(sendSocketMessage(data));

    // we can get the value of data.seq after sendSocketMessage
    // update draftRoom's requestId with data.seq
    updateDraftRooms(data.seq);
    return true;
  };

export const addRoomRequest = () => (dispatch) => {
  dispatch(addRoomsRequest(1));
};

export const addRoomWhileStart = () => (dispatch, getState) => {
  const state = getState();
  const {
    breakoutRoom: { roomNextId },
  } = state;
  const host = hostAttendeeForBreakoutRoomSelector(state);

  // CREATE_BO_BY_INDEX_WHILE_START will auto append index after name
  const roomName = `${BREAKOUTROOM_DEFAULT_ROOM_NAME} `;

  const data = {
    evt: socketEventTypes.WS_CONF_BO_CREATE_REQ,
    body: {
      targetID: host ? host.userId : null,
      way: BO_ROOM_CREATE_WAY_WHILE_START.CREATE_BO_BY_INDEX_WHILE_START,
      BOName: roomName,
    },
  };
  dispatch(sendSocketMessage(data));
  dispatch(setRoomLocalId(roomNextId + 1));
};

export const deleteRoomWhileStart = (boId) => (dispatch, getState) => {
  const state = getState();
  const host = hostAttendeeForBreakoutRoomSelector(state);

  const data = {
    evt: socketEventTypes.WS_CONF_BO_DELETE_REQ,
    body: {
      targetID: host ? host.userId : null,
      targetBID: boId,
    },
  };
  dispatch(sendSocketMessage(data));
};

export const renameRoomWhileStart = (boId, newName) => (dispatch, getState) => {
  const state = getState();
  const host = hostAttendeeForBreakoutRoomSelector(state);

  const data = {
    evt: socketEventTypes.WS_CONF_BO_RENAME_REQ,
    body: {
      targetID: host ? host.userId : null,
      targetBID: boId,
      BOName: newName,
    },
  };
  dispatch(sendSocketMessage(data));
};

export const createBreakoutRooms =
  (roomSize, distributionPattern, roomNames = ['']) =>
  (dispatch) => {
    dispatch(
      setOptionsValueParticipantsChooseRoom(
        distributionPattern === ROOMS_DISTRIBUTION_PATTERN.selfSelect,
      ),
    );
    dispatch(setBoRoomsDistributionPattern(distributionPattern));
    return dispatch(resetAndBatchAddRooms(roomSize, roomNames));
  };

// execute it when clicking 'Recreate' after clicking 'Create All Rooms', and clicking 'Create' in initial create BO window.
export const resetAndBatchAddRooms = (roomSize, roomNames) => (dispatch) => {
  dispatch(setBoLoading(true));
  dispatch(resetRoomLocalId());
  dispatch(resetAllRooms());
  dispatch(setPreRoomSize(roomSize));
  dispatch(setPreAssignmentEnabled(false));
  return dispatch(addRoomsRequest(roomSize, roomNames, true));
};

/**
 * We need send a leave bo message to RWG
 * when
 * 1.host join another room when he is in a room
 * 2.others is moved by the host to another room
 * 3.all leave from bo
 */
const sendLeaveBoMessage = () => (dispatch) => {
  easyStore.easySet(
    'JOIN_MEETING_FLOW_LEAVE_BO',
    true,
    storeType.sessionStorage,
  );
  const data = {
    evt: socketEventTypes.WS_CONF_BO_LEAVE_REQ,
    body: {
      reason: 1,
    },
  };
  dispatch(sendSocketMessage(data));
};

export const leaveBoMeetingAction = () => (dispatch, getState) => {
  const {
    breakoutRoom: {
      attendee: { autoLeaveBoMeetingTimer },
    },
  } = getState();
  // clear the timer
  if (autoLeaveBoMeetingTimer) {
    clearTimeout(autoLeaveBoMeetingTimer);
    setAutoLeaveBoMeetingTimer(null);
  }
  dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.RETURNING));
  dispatch(sendLeaveBoMessage());
  dispatch(clearAllFileMessage());
  dispatch(clearAllOneChatFileMessage());
  dispatch(clearNewChatAllFileMessage());
  dispatch(closeSocket(true));
};

export const joinBoMeetingAction = () => (dispatch, getState) => {
  const {
    meeting: { isHost },
    breakoutRoom: {
      attendee: { status },
    },
  } = getState();
  dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.JOINING));
  dispatch(setJoinBoCmd(BO_COMMAND_TYPES.JOIN));
  dispatch(clearAllFileMessage());
  dispatch(clearNewChatAllFileMessage());
  dispatch(clearAllOneChatFileMessage());

  if (isHost && isInRoom(status)) {
    dispatch(sendLeaveBoMessage());
  }
  dispatch(closeSocket(true));
};

export const beMovedToBoAction = () => (dispatch) => {
  dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.JOINING));
  dispatch(setJoinBoCmd(BO_COMMAND_TYPES.SWITCH));
  dispatch(sendLeaveBoMessage());
  dispatch(clearAllFileMessage());
  dispatch(clearNewChatAllFileMessage());
  dispatch(clearAllOneChatFileMessage());
  dispatch(closeSocket(true));
};

const checkHostNeedCloseBo = () => (dispatch, getState) => {
  const {
    meeting: { isHost },
    breakoutRoom: {
      UI: { showManageBoRoomsWindow },
      controlStatus,
      attendee: { status },
    },
  } = getState();
  if (!isHost) {
    return;
  }
  if (isRoomClosing(controlStatus) && !showManageBoRoomsWindow) {
    dispatch(closeBreakoutRoomFinnally());
    if (isInRoom(status)) {
      dispatch(leaveBoMeetingAction());
    }
  }
};

const hostStartBreakoutRoomRequest = () => (dispatch, getState) => {
  const status = CONTROL_STATUS.IN_PROGRESS;
  const state = getState();
  const proto = getStartBoRequestBodyProto(
    state,
    status,
    BO_ROOM_STATUS.STARATED,
  );
  const data = { evt: socketEventTypes.WS_CONF_BO_START_REQ, body: proto };

  dispatch(sendSocketMessage(data));
};

const coHostStartBreakoutRoomRequest = () => (dispatch, getState) => {
  const status = CONTROL_STATUS.IN_PROGRESS;
  const state = getState();
  const proto = getStartBoRequestBodyProto(
    state,
    status,
    BO_ROOM_STATUS.STARATED,
  );
  const host = hostAttendeeForBreakoutRoomSelector(state);
  const data = {
    evt: socketEventTypes.WS_CONF_BO_COHOST_START_REQ,
    body: { proto: proto.proto, targetID: host ? host.userId : null },
  };
  if (!host) {
    logHostNotFound();
  }

  dispatch(sendSocketMessage(data));
};

export const openBreakoutRoom = () => (dispatch, getState) => {
  const state = getState();
  const currentRole = currentRoleSelector(state);

  if (currentRole.isHost) {
    dispatch(hostStartBreakoutRoomRequest());
  } else if (currentRole.bCoHost) {
    dispatch(coHostStartBreakoutRoomRequest());
  } else {
    return;
  }

  promptA11yInfo('Breakout Room has been opened');
};

const hostCloseBreakoutRoomRequest = () => (dispatch) => {
  const status = CONTROL_STATUS.CLOSING;
  const data = { evt: socketEventTypes.WS_CONF_BO_STOP_REQ, body: { status } };

  dispatch(sendSocketMessage(data));
};

const coHostCloseBreakoutRoomRequest = () => (dispatch, getState) => {
  const status = CONTROL_STATUS.CLOSING;
  const host = hostAttendeeForBreakoutRoomSelector(getState());
  const data = {
    evt: socketEventTypes.WS_CONF_BO_COHOST_STOP_REQ,
    body: { status, targetID: host ? host.userId : null },
  };
  if (!host) {
    logHostNotFound();
  }
  dispatch(sendSocketMessage(data));
};

export const closeBreakoutRoom = () => (dispatch, getState) => {
  const state = getState();
  const {
    breakoutRoom: {
      options: { waitseconds },
      controlStatus: currentStatus,
    },
  } = state;
  const currentUserIsAbleToManageBo =
    currentUserIsAbleToManageBoSelector(state);
  if (!currentUserIsAbleToManageBo || !isRoomInProgress(currentStatus)) {
    return false;
  }

  const currentRole = currentRoleSelector(state);
  if (currentRole.isHost) {
    dispatch(hostCloseBreakoutRoomRequest());
  } else if (currentRole.bCoHost) {
    dispatch(coHostCloseBreakoutRoomRequest());
  } else {
    return false;
  }

  dispatch(setHasReceiveBoClosingRes(false));

  // when host is in bo,we should clear the storage flag after he clicks the close all rooms button
  clearUserJoinBoInfoInStorage();
  // if the host in bo closed the bo manage window when it's in closing status
  // we need add the timer to make the host to close bo
  if (waitseconds > 0) {
    setTimeout(() => {
      dispatch(checkHostNeedCloseBo());
    }, waitseconds * 1000);
  }
  return true;
};

export const closeBreakoutRoomFinnally = () => (dispatch, getState) => {
  const {
    breakoutRoom: { controlStatus },
    meeting: { isHost },
  } = getState();
  if (!isHost || !isRoomClosing(controlStatus)) {
    return;
  }
  const status = CONTROL_STATUS.CLOSED;
  dispatch(setControlStatus(status));
  const data = {
    evt: socketEventTypes.WS_CONF_BO_STOP_REQ,
    body: {
      status,
    },
  };

  dispatch(sendSocketMessage(data));
  promptA11yInfo('Breakout Room has been closed');
};

export const requestBotokenByBoId = (boId) => (dispatch) => {
  if (boId) {
    easyStore.easySet(
      'JOIN_MEETING_FLOW_JOIN_BO',
      true,
      storeType.sessionStorage,
    );
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_BO_JOIN_REQ,
        body: {
          targetBID: boId,
        },
      }),
    );
  }
};

/**
 * if you want to join bo, you need get the boToken first from this method
 * @param {*} targetRoom
 */
export const invokeBoJoinInterceptor = (targetRoom) => (dispatch, getState) => {
  const {
    meeting: { isHost },
    breakoutRoom: {
      attendee: { boConfId, room },
    },
  } = getState();
  if (isHost && targetRoom) {
    // step 1: if room has boToken,we can join bo direct
    // step 2: if not send a req to get the target room's boToken with the boId
    // step 3: RWG will send a joinRequest indication
    if (targetRoom.boToken && boConfId) {
      // always request new bo token now;
      dispatch(requestBotokenByBoId(targetRoom.boId));
    } else {
      dispatch(requestBotokenByBoId(targetRoom.boId));
    }
  } else if (
    room.boToken &&
    boConfId &&
    (!targetRoom || room.boId === targetRoom.boId)
  ) {
    // always request new bo token now;
    dispatch(requestBotokenByBoId(targetRoom ? targetRoom.boId : room.boId));
  } else {
    dispatch(requestBotokenByBoId(targetRoom ? targetRoom.boId : room.boId));
  }
};

export const joinBo = (targetRoom) => (dispatch, getState) => {
  const state = getState();
  const {
    meeting: { isHost },
    breakoutRoom: {
      attendee: { room },
    },
  } = state;

  if (isHost || room.boId === targetRoom.boId) {
    dispatch(invokeBoJoinInterceptor(targetRoom));
  } else {
    const host = hostAttendeeForBreakoutRoomSelector(state);
    if (host) {
      // co-host (or participants with slef select caps) join bo
      // step 1: co-host sends WS_CONF_BO_WANT_JOIN_REQ command to the host
      // step 2: the host receives the indication
      // step 3: the host updates the boList and sends WS_CONF_BO_SWITCH_REQ
      // step 4: co-host receive the indication and join the bo auto
      // Here is step 1
      // UPDATE: bolist update logic has been moved to RWG. Step 3 is not needed
      // anymore. Host and co-host and other users with self select caps just
      // send WS_CONF_BO_WANT_JOIN_REQ and receive the indication.

      dispatch(
        sendSocketMessage({
          evt: socketEventTypes.WS_CONF_BO_WANT_JOIN_REQ,
          body: {
            targetBID: targetRoom.boId,
            targetID: host.userId, // host userId in main session
          },
        }),
      );
    }
  }
};

export const hostLeaveBo = () => (dispatch) => {
  dispatch(leaveBoMeetingAction());
};

export const handleAddRoomIndication = (message) => (dispatch, getState) => {
  const {
    body: { bid },
    seq,
  } = message;
  const {
    breakoutRoom: { preRoomSize },
  } = getState();
  const draftRoom = getDraftRoom(seq);
  if (draftRoom && preRoomSize === 0) {
    dispatch(
      addRoom({
        boId: bid,
        name: draftRoom.name,
        boToken: '', // this api will not return the botoken any more
        boStatus: BO_ROOM_STATUS.GOT_TOKEN,
        hostId: '',
        attendeeIdList: draftRoom.attendeeIdList,
      }),
    );
    dispatch(setPreRoomSize(-1));
    clearDraftRooms();
    addRoomDebounce(draftRoom.name);
  }
};

export const handleAddRoomsIndication = (messages) => (dispatch) => {
  batchCreateRooms(dispatch, messages);
};

export const sendBroadcastMessage = (message) => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_BO_BROADCAST_REQ,
      body: {
        textContent: message,
      },
    }),
  );
};

const moveTargetUserToBo =
  (targetUserId, targetZoomId, targetRoomId, eventType, isCoHost = false) =>
  (dispatch, getState) => {
    if (!isCoHost) {
      const {
        breakoutRoom: {
          attendee: { status },
          mainSessionAttendeeList,
        },
      } = getState();
      let userId = targetUserId;
      if (isInRoom(status)) {
        const targetAttendeeInMeeting = mainSessionAttendeeList.find(
          (attendee) => attendee.zoomID === targetZoomId,
        );
        if (targetAttendeeInMeeting) {
          userId = targetAttendeeInMeeting.userId;
        }
      }
      const data = {
        evt: eventType,
        body: {
          targetBID: targetRoomId,
          targetID: userId,
        },
      };
      dispatch(sendSocketMessage(data));
    } else {
      const state = getState();
      const {
        breakoutRoom: {
          attendee: { status },
          mainSessionAttendeeList,
        },
        attendeesList: { attendeesList },
      } = state;
      let userGUID = null;
      const host = hostAttendeeForBreakoutRoomSelector(state);

      if (isNotInRoom(status)) {
        const targetAttendee = attendeesList.find(
          (attendee) => attendee.userId === targetUserId,
        );
        if (targetAttendee) {
          userGUID = targetAttendee.userGUID;
        }
      } else {
        const targetAttendeeInMeeting = mainSessionAttendeeList.find(
          (attendee) => attendee.zoomID === targetZoomId,
        );
        if (targetAttendeeInMeeting) {
          userGUID = targetAttendeeInMeeting.userGUID;
        }
      }
      const data = {
        evt: eventType,
        body: {
          targetBID: targetRoomId,
          userGUID,
          targetID: host ? host.userId : null,
        },
      };
      if (!host) {
        logHostNotFound();
      }
      dispatch(sendSocketMessage(data));
    }
  };

export const assignUnassignedUserToBo =
  (targetUserId, targetZoomId, targetRoomId) => (dispatch, getState) => {
    const state = getState();
    const currentUserIsAbleToManageBo =
      currentUserIsAbleToManageBoSelector(state);
    if (!currentUserIsAbleToManageBo) {
      return;
    }

    const currentRole = currentRoleSelector(state);
    if (currentRole.isHost) {
      dispatch(
        moveTargetUserToBo(
          targetUserId,
          targetZoomId,
          targetRoomId,
          socketEventTypes.WS_CONF_BO_ASSIGN_REQ,
          false,
        ),
      );
    } else if (currentRole.bCoHost) {
      dispatch(
        moveTargetUserToBo(
          targetUserId,
          targetZoomId,
          targetRoomId,
          socketEventTypes.WS_CONF_BO_COHOST_ASSIGN_REQ,
          true,
        ),
      );
    }
  };
// usersWithRooms should be a list like: [{userGUID: "GUID", targetBID: "bo Id"}]
export const requestAssignBatchUserToBo = (usersWithRooms) => (dispatch) => {
  const data = {
    evt: socketEventTypes.WS_CONF_BO_ASSIGN_BATCH_REQ,
    body: usersWithRooms,
  };

  dispatch(sendSocketMessage(data));
};

export const moveUserToBo =
  (targetUserId, targetZoomId, targetRoomId) => (dispatch, getState) => {
    const state = getState();
    const currentUserIsAbleToManageBo =
      currentUserIsAbleToManageBoSelector(state);
    if (!currentUserIsAbleToManageBo) {
      return;
    }
    const currentRole = currentRoleSelector(state);
    if (currentRole.isHost) {
      dispatch(
        moveTargetUserToBo(
          targetUserId,
          targetZoomId,
          targetRoomId,
          socketEventTypes.WS_CONF_BO_SWITCH_REQ,
          false,
        ),
      );
    } else if (currentRole.bCoHost) {
      dispatch(
        moveTargetUserToBo(
          targetUserId,
          targetZoomId,
          targetRoomId,
          socketEventTypes.WS_CONF_BO_COHOST_ASSIGN_REQ,
          true,
        ),
      );
    }
  };

const hostMoveTargetUserToMainSession = (userGUID) => (dispatch) => {
  const data = {
    evt: socketEventTypes.WS_CONF_BO_MOVE_TO_MAIN_SESSION_REQ,
    body: { userGUID },
  };

  dispatch(sendSocketMessage(data));
};

const coHostMoveTargetUserToMainSession =
  (userGUID) => (dispatch, getState) => {
    const state = getState();
    const host = hostAttendeeForBreakoutRoomSelector(state);

    const data = {
      evt: socketEventTypes.WS_CONF_BO_COHOST_MOVE_TO_MAIN_SESSION_REQ,
      body: { userGUID, targetID: host ? host.userId : null },
    };
    if (!host) {
      logHostNotFound();
    }
    dispatch(sendSocketMessage(data));
  };

export const moveUserToMainSession = (userGUID) => (dispatch, getState) => {
  if (!userGUID) return;
  const state = getState();

  const currentRole = currentRoleSelector(state);
  if (currentRole.isHost) {
    dispatch(hostMoveTargetUserToMainSession(userGUID));
  } else if (currentRole.bCoHost) {
    dispatch(coHostMoveTargetUserToMainSession(userGUID));
  }
};

/**
 * This action is for the participant
 */
export const sendAskForHelpMessage = () => (dispatch, getState) => {
  const state = getState();
  const {
    meeting: { zoomId },
    breakoutRoom: { hostZoomId, mainSessionAttendeeList },
  } = state;

  const currentUserIsAbleToManageBo =
    currentUserIsAbleToManageBoSelector(state);
  if (currentUserIsAbleToManageBo) {
    return;
  }
  const hostInMeeting = mainSessionAttendeeList.find(
    (attendee) => attendee.zoomID === hostZoomId,
  );
  const meInMeeting = mainSessionAttendeeList.find(
    (attendee) => attendee.zoomID === zoomId,
  );
  if (hostInMeeting && meInMeeting) {
    const data = {
      evt: socketEventTypes.WS_CONF_BO_HELP_REQ,
      body: {
        // requestId: meInMeeting.userId, // self id in mainSession
        targetID: hostInMeeting.userId, // host id in mainSession
      },
    };
    dispatch(sendSocketMessage(data));
  }
};
/**
 * This action is for the host
 * @param {*} result
 */
export const hostSendResToAsker = (result, destUserId) => (dispatch) => {
  const data = {
    evt: socketEventTypes.WS_CONF_BO_HELP_RESULT_REQ,
    body: {
      targetID: destUserId, // asker id in mainSession
      helpResult: result,
    },
  };
  dispatch(sendSocketMessage(data));
};

export const tryCheckPutOnHoldAttendeeInBoRoom =
  (message) => (dispatch, getState) => {
    if (message.body.update) {
      const targetUsers = message.body.update.filter((user) => user.bHold);
      const {
        breakoutRoom: { roomList },
        attendeesList: { attendeesList },
      } = getState();
      if (targetUsers.length > 0 && roomList.length > 0) {
        targetUsers.forEach((targetUser) => {
          const targetAttendee = attendeesList.find(
            (attendee) => attendee.userId === targetUser.id,
          );
          if (targetAttendee) {
            dispatch(removeBoAssignedUserGuid(targetAttendee.userGUID));
          }
        });
      }
    }
  };

const handlePWAEvent4ReturnToMainSession = () => (dispatch, getState) => {
  const inviterName =
    inviterNameSelector(getState()) ||
    BREAKOUTROOM_INVITE_JOIN_ROOM_TIP_DEFAULT_HOST;
  sendMsgToPWA(PWAMeetingEvent.RETURN_MAIN_SESSION, { inviterName });
};

const handleBoActivityStatus = (body) => (dispatch) => {
  const { activityRoster, activityTalking, requestID } = body;
  const payload = {
    activityRoster: parseInt(activityRoster, 10),
    activityTalking: Boolean(parseInt(activityTalking, 10)),
    requestID,
  };
  dispatch(updateBreakoutRoomActivityStatusByUser(payload));
};
export const handleBoActivityStatusFromRosterIndication =
  (message) => (dispatch) => {
    if (!_.isEmpty(message.body.add)) {
      const userList = message.body.add || [];
      userList.forEach((user) => {
        const { rosterStatus, id } = user;
        if (rosterStatus) {
          const payload = {
            activityRoster: parseInt(rosterStatus, 10),
            requestID: id,
          };
          dispatch(updateBreakoutRoomActivityStatusByUser(payload));
        }
      });
    }
    if (!_.isEmpty(message.body.update)) {
      const userList = message.body.update || [];
      userList.forEach((user) => {
        const { rosterStatus, id } = user;
        if (rosterStatus) {
          const payload = {
            activityRoster: parseInt(rosterStatus, 10),
            requestID: id,
          };
          dispatch(updateBreakoutRoomActivityStatusByUser(payload));
        }
      });
    }
  };

export const handleBoCommandIndication = (body) => (dispatch, getState) => {
  const { commandType } = body;
  const state = getState();
  const inviterName = inviterNameSelector(state);
  const {
    attendeesList: { attendeesList },
    meeting: {
      zoomId,
      userGUID,
      currentUser: { isModerator, userId },
    },
    breakoutRoom: {
      UI: { showNeedHelpDialog },
      attendee: {
        status,
        room: { name: roomName, boId },
      },
      mainSessionAttendeeList,
      options: { autoJoin, isAutoMovetoMainSessionEnabled },
      roomList,
      whoStart,
    },
  } = state;
  switch (commandType) {
    case BO_COMMAND_TYPES.JOIN: {
      const { targetBID, botoken, confID, requestSourceID, joinReason } = body;
      const parsedJoinReason = parseInt(joinReason, 10);
      const myRoom = isMeShouldJoinBo(roomList, userGUID);
      // find myself and join the bo room
      if (myRoom && myRoom.boId === targetBID) {
        const updateRoom = {
          ...myRoom,
          boToken: botoken,
        };
        // when I has been invited by the host I need't join bo
        // popup a dialog to prompt the user
        readyToJoinBo(dispatch, updateRoom, zoomId, confID);

        dispatch(setJoinReason(parsedJoinReason));
        // popup a dialog to confirm
        if (!autoJoin && `${userId}` !== requestSourceID) {
          if (parsedJoinReason === BO_JOIN_REASONS.START) {
            dispatch(setLatestInviter(whoStart));
          } else {
            const inviter = [...attendeesList, ...mainSessionAttendeeList].find(
              (attendee) => attendee.userId === parseInt(requestSourceID, 10),
            );
            if (inviter) {
              dispatch(setLatestInviter(inviter.userGUID));
            }
          }
          dispatch(setInviteJoinBoRoomDialogVisible(true));
          sendMsgToPWA(PWAMeetingEvent.INVITED_TO_BO, {
            hostName: inviterName,
            roomName,
          });
          // if you refresh the page,we can check if popup the dialog auto
          setUserReceiveJoinBoCmdStorage();
        } else {
          dispatch(joinBoMeetingAction());
        }
      }
      break;
    }
    case BO_COMMAND_TYPES.SWITCH: {
      const { targetBID, botoken, confID, requestSourceID } = body;
      const myRoom = isMeShouldJoinBo(roomList, userGUID);
      // find myself and join the bo room
      if (myRoom && myRoom.boId === targetBID) {
        // when I has been invited by the host I need't join bo
        // popup a dialog to prompt the user
        const updateRoom = {
          ...myRoom,
          boToken: botoken,
        };
        if (isBeInvited(status)) {
          readyToJoinBo(dispatch, updateRoom, zoomId, confID);
          if (!autoJoin && `${userId}` !== requestSourceID) {
            dispatch(setLatestInviter(parseInt(body.requestSourceID, 10)));
            dispatch(setInviteJoinBoRoomDialogVisible(true));
            setUserReceiveJoinBoCmdStorage();
          } else {
            dispatch(joinBoMeetingAction());
          }
        } else {
          dispatch(setAttendeeRoomInfo(updateRoom));
          dispatch(setJoinBoConfId(confID));
          dispatch(beMovedToBoAction());
        }
      }
      break;
    }
    case BO_COMMAND_TYPES.LEAVE: {
      if (isNotInRoom(status)) {
        dispatch(setInviteJoinBoRoomDialogVisible(false));
        dispatch(setRequestJoinBoRoomDialogVisible(false));
        dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.INITIAL));
        return false;
      }
      const { waitTime, joinMethod } = body;
      const waitTimeSeconds = parseInt(waitTime, 10);
      const parsedJoinMethod = parseInt(joinMethod, 10);
      if (waitTimeSeconds > 0) {
        // Moderators need't show this dialog
        if (!isModerator) {
          dispatch(setRoomWillCloseDialogVisible(true));
          sendMsgToPWA(PWAMeetingEvent.BO_WILL_CLOSE);
        } else {
          dispatch(setWhenCloseBoStarttime(new Date().getTime() / 1000));
          dispatch(setManageBoRoomsWindowVisible(true));
        }
        // dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.TIME_UP));
        const autoLeaveBoMeetingTimer = setTimeout(() => {
          dispatch(leaveBoMeetingAction());
          setAutoLeaveBoMeetingTimer(null);
        }, waitTimeSeconds * 1000);
        dispatch(setAutoLeaveBoMeetingTimer(autoLeaveBoMeetingTimer));
      } else if (parsedJoinMethod === BO_JOIN_METHODS.NORMAL_JOIN) {
        if (isAutoMovetoMainSessionEnabled) {
          dispatch(leaveBoMeetingAction());
        } else {
          dispatch(setLatestInviter(parseInt(body.requestSourceID, 10)));
          dispatch(setReturnToMainSessionDialogVisible(true));
          dispatch(handlePWAEvent4ReturnToMainSession());
        }
      } else if (parsedJoinMethod === BO_JOIN_METHODS.FORCE_JOIN) {
        dispatch(leaveBoMeetingAction());
      } else {
        dispatch(leaveBoMeetingAction());
      }
      clearUserJoinBoInfoInStorage();
      break;
    }
    case BO_COMMAND_TYPES.BROADCAST: {
      const { textContent, requestID } = body;
      if (!textContent) {
        break;
      }
      let broadcaster = {};
      if (isNotInRoom(status)) {
        broadcaster = attendeesList.find((item) => item.userId === requestID);
      } else {
        broadcaster = mainSessionAttendeeList.find(
          (item) => item.userId === requestID,
        );
      }
      beginDecrypt({
        decryptedText: textContent,
        type: ivType.BO_BROADCAST,
        userId: broadcaster.zoomID,
      }).then(({ message }) => {
        dispatch(
          setBroadcastMessage({
            message,
            userId: requestID,
            zoomId: broadcaster.zoomID,
          }),
        );
      });
      break;
    }
    case BO_COMMAND_TYPES.HELP_REQ: {
      // just the host can receive this request
      const { requestID } = body;
      if (!requestID) {
        break;
      }
      // if the host is alreay in the asker's bo,we will send a res to tell the asker
      if (isInRoom(status)) {
        // the host is in the room,so we need get the asker's boId from mainSessionAttendeeList
        const askerInMeeting = mainSessionAttendeeList.find(
          (attendee) => attendee.userId === requestID,
        );
        if (askerInMeeting && askerInMeeting.bid === boId) {
          dispatch(
            hostSendResToAsker(BO_HOST_HELP_REQ_TYPES.ALREADY_IN, requestID),
          );
          break;
        }
      }
      if (showNeedHelpDialog && showNeedHelpDialog !== requestID) {
        // if the dialog for the host to help has opend,
        // we will send a response to the asker the host is busy
        dispatch(hostSendResToAsker(BO_HOST_HELP_REQ_TYPES.BUSY, requestID));
      } else {
        // normally
        dispatch(
          hostSendResToAsker(BO_HOST_HELP_REQ_TYPES.HAS_RECEIVED, requestID),
        );
        dispatch(setNeedHelpDialogVisible(requestID));
      }
      break;
    }
    case BO_COMMAND_TYPES.HELP_RES: {
      const { helpResult } = body;
      const helpResultValue = +helpResult;
      let toastMessage = '';
      let toastType = 'success';
      if (helpResultValue === BO_HOST_HELP_REQ_TYPES.HAS_RECEIVED) {
        toastMessage = BREAKOUTROOMS_INVITE_HOST_SUCCESS_TIP;
      } else if (helpResultValue === BO_HOST_HELP_REQ_TYPES.BUSY) {
        toastMessage = BREAKOUTROOMS_INVITE_HOST_FAILED_TIP;
        toastType = 'error';
      } else if (helpResultValue === BO_HOST_HELP_REQ_TYPES.IGNORE) {
        toastMessage = BREAKOUTROOMS_INVITE_HOST_FAILED_TIP;
        toastType = 'error';
      } else {
        toastMessage = BREAKOUTROOMS_INVITE_HOST_SUCCESS_TIP2;
      }
      wcToast({ text: toastMessage, type: toastType });
      break;
    }
    case BO_COMMAND_TYPES.BO_ACTIVITY: {
      dispatch(handleBoActivityStatus(body));
      break;
    }
    // TODO add break before default

    default:
      return false;
  }
  return false;
};

export const handleHostUpdateIndication = (message) => (dispatch, getState) => {
  const {
    body: { bHost },
  } = message;
  const {
    breakoutRoom: {
      controlStatus,
      attendee: { status },
    },
  } = getState();
  if (bHost) {
    // need clear the localstorage of webClient_Bo_HasJoinBoMeeting
    // when the value is ATTENDEE_STATUS.BE_INVITED
    const hasJoinBoStatus = easyStore.easyGet(
      SESSIONSTORAGE_KEYS.webClient_Bo_HasJoinBoMeeting,
    );
    if (hasJoinBoStatus) {
      const [attendeeStatus] = hasJoinBoStatus.split(';');
      if (isBeInvited(attendeeStatus)) {
        clearUserJoinBoInfoInStorage();
      }
    }
    // We need hide the dialogs because they may be opend when I'm from an attendee to be the host
    dispatch(setInviteJoinBoRoomDialogVisible(false));
    dispatch(setRequestJoinBoRoomDialogVisible(false));
    dispatch(setAskForHelpDialogVisible(false));

    if (isRoomInProgress(controlStatus) || isRoomClosing(controlStatus)) {
      // open the bo manage panel when I'm the host
      // wherever I'm in the main session meeting or a room
      dispatch(setManageBoRoomsWindowVisible(true));
    }
  } else if (isInRoom(status)) {
    // if the host is not the host anymore and in a room,we need set the value false.
    dispatch(setIsHostBeforeJoinBo(false));
  }
  setIsHostAtLocal(!!bHost);
};

const checkLoadingLayerStatus = (params) => (dispatch, getState) => {
  const {
    meeting: { loading },
    breakoutRoom: {
      attendee: { boConfId },
    },
  } = getState();
  const {
    breakoutRoom: {
      attendee: { status },
    },
  } = getState();
  if (!loading) {
    if (isReturningToMainSession(status)) {
      changeToReturnedStatus(dispatch, params, boConfId);
    }
  } else {
    // use the setTimeout function to fix the delta time between the loading-layer status
    setTimeout(() => {
      dispatch(checkLoadingLayerStatus(params));
    }, 100);
  }
};

export const handleUpdateBoAttributeIndication =
  (body, isSecondChannel) => (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { zoomId, isHost, userGUID },
      breakoutRoom: {
        UI: { showTimeUpNotifyDialog },
        attendee: {
          status,
          boConfId,
          room: { boId, boToken },
        },
        isSecondWSChannelReady,
        hugeBo,
        isHostBeforeJoinBo,
      },
    } = state;
    dispatch(setIsAddRoomWaitingBoAttributeIndication(false));

    const { proto } = body;
    const {
      autoJoin,
      backToMainSession,
      participantsChooseRoom,
      timer,
      timerAutoEnd,
      timerDuration,
      waitseconds,
      controlstatus,
      nameindex,
      update,
      startTimeOnMMR,
      preAssignmentEnabled,
      whoStart,
      whoStop,
      isAutoMovetoMainSessionEnabled,
    } = boAttributeIndicationAdapter(proto, hugeBo);

    dispatch(setOptionsValueAutojoin(!!autoJoin));
    dispatch(setOptionsValueBacktomainsession(backToMainSession));
    dispatch(setOptionsValueParticipantsChooseRoom(participantsChooseRoom));
    dispatch(setOptionsValueAutoclosetimer(!!timer));
    dispatch(setOptionsValueTimerduration(timerDuration));
    dispatch(setOptionsValueTimerautoend(!!timerAutoEnd));
    dispatch(setOptionsValueNeedcountdown(waitseconds > 0));
    dispatch(setOptionsValueWaitseconds(waitseconds));
    dispatch(updateRooms(update));
    dispatch(setRoomLocalId(nameindex));
    dispatch(setRoomStartedTime(startTimeOnMMR));
    dispatch(setControlStatus(controlstatus));
    dispatch(setPreAssignmentEnabled(preAssignmentEnabled));
    dispatch(setWhoStart(whoStart));
    dispatch(setWhoStop(whoStop));
    dispatch(
      setOptionsValueAutomovetomainsession(isAutoMovetoMainSessionEnabled),
    );

    dispatch(incProtoCount());

    if (isRoomInProgress(controlstatus)) {
      dispatch(setHasReceiveBoClosingRes(false));

      if (!isInRoom(status) && !isJoiningRoom(status)) {
        // check the status from localstorage
        // so we can join bo directly
        const checkResult = checkFailoverAttendeeBoStatus(dispatch, getState);
        // check the breakout room button is visible or hidden for me
        // when I'm back to main session from bo
        if (!checkResult) {
          const myRoom = isMeShouldJoinBo(update, userGUID);
          // we need set the status to be RETURN_MAIN_SESSION
          if (isReturningToMainSession(status)) {
            dispatch(
              checkLoadingLayerStatus({
                myRoom,
                boId,
                boToken,
                zoomId,
              }),
            );
          } else if (myRoom) {
            readyToJoinBo(
              dispatch,
              {
                ...changeRoomDataFromServerToLocal(myRoom),
                boToken,
              },
              zoomId,
              boConfId,
            );
          }
        }
      } else if (
        isInRoom(status) &&
        isSecondChannel &&
        !isSecondWSChannelReady
      ) {
        let myRoom = isMeShouldJoinBo(update, userGUID);
        if (myRoom) {
          myRoom = changeRoomDataFromServerToLocal(myRoom);
          // we should move the user to the correct room
          if (boId !== myRoom.boId && !isHost && !isHostBeforeJoinBo) {
            dispatch(invokeBoJoinInterceptor(myRoom));
          }
        }
      }
    }

    if (!isRoomInProgress(controlstatus)) {
      if (showTimeUpNotifyDialog) {
        dispatch(setTimeUpNotifyDialogVisible(false));
      }
    }

    if (isRoomClosing(controlstatus)) {
      dispatch(setHasReceiveBoClosingRes(true));
      if (isHost) {
        // check if it needs to close the closing timer when I'm the host
        if (waitseconds === 0) {
          dispatch(closeBreakoutRoomFinnally());
          if (isInRoom(status)) {
            dispatch(leaveBoMeetingAction());
          }
        }
      }
      dispatch(resetIsSharingToBO());
    }

    if (isRoomClosed(controlstatus)) {
      // if the room has be closed,I'm back to the main session
      // we need set the status to be RETURN_MAIN_SESSION
      if (isReturningToMainSession(status)) {
        dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.RETURN_MAIN_SESSION));
      } else if (isBeInvited(status)) {
        dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.INITIAL));
      }
      // if I'm still in the room which had be closed, we'll leave the room right now
      if (isInRoom(status) || isJoiningRoom(status)) {
        dispatch(leaveBoMeetingAction());
      }
      // when bo is closed we need clear the storage info
      clearUserJoinBoInfoInStorage();
      clearUserReceiveJoinBoCmdStorage();
      dispatch(resetIsSharingToBO());
    }
    clearBCoHostInStorage();
  };

export const handleBoJoinRes = (body) => (dispatch, getState) => {
  const { bid, botoken, confID } = body;
  const {
    meeting: { isHost, zoomId },
    breakoutRoom: {
      roomList,
      attendee: { status },
    },
  } = getState();
  // Here is step 2: host join bo
  if (isHost) {
    const targetRoom = roomList.find((room) => room.boId === bid);
    if (targetRoom) {
      hostReadyToJoinBo(
        dispatch,
        {
          ...targetRoom,
          boToken: botoken,
        },
        zoomId,
        confID,
      );
      dispatch(joinBoMeetingAction());
    }
  } else {
    const targetRoom = roomList.find((room) => room.boId === bid);
    if (targetRoom) {
      readyToJoinBo(
        dispatch,
        {
          ...targetRoom,
          boToken: botoken,
        },
        zoomId,
        confID,
      );
      if (isInRoom(status)) {
        dispatch(beMovedToBoAction());
      } else {
        dispatch(joinBoMeetingAction());
      }
    }
  }
};

export const handleBoMainSessionSocketMessage =
  (message) => (dispatch, getState) => {
    if (!message) {
      return null;
    }
    const type = message.evt;
    switch (type) {
      case socketEventTypes.WS_CONF_JOIN_RES: {
        handleMainSessionRes(message, dispatch);
        break;
      }
      case socketEventTypes.WS_CONF_BO_ATTRIBUTE_INDICATION: {
        const { body } = message;
        dispatch(handleUpdateBoAttributeIndication(body, true));
        dispatch(setSecondWsChannelReady(true));
        break;
      }
      case socketEventTypes.WS_CONF_BO_COMMAND_INDICATION: {
        const { body } = message;
        return dispatch(handleBoCommandIndication(body));
      }
      case socketEventTypes.WS_CONF_ROSTER_INDICATION: {
        const { body } = message;
        if (body.add && body.add.length > 0) {
          dispatch(addMainSessionAttendeeList(removeDuplicates(body.add)));
          updateAttendeesList(body.add, 1, dispatch, getState());
          updateUserRosterInfoToSDK({
            addUsers: body.add,
            isFromMainSession: true,
            mediaActionType: sdkIvTypeKeyEnum.SHARING_DECODE,
          });
          updateUserRosterInfoToSDK({
            addUsers: body.add,
            isFromMainSession: true,
            mediaActionType: sdkIvTypeKeyEnum.AUDIO_DECODE,
          });
        }
        if (body.update && body.update.length > 0) {
          const updateData = removeDuplicates(body.update);
          dispatch(updateMainSessionAttendeeList(updateData));
          // 1.update mainSessionAttendeeList first(shareSsrc attr)
          // 2.so this function can get the shareSsrc attr
          dispatch(updateSharingInfoFromMainSession(updateData));
          updateAttendeesList(body.update, 2, dispatch, getState());
          // if sharer stop, need to unmute share audio.
          dispatch(restoreShareAudioStatus(body.update));
        }
        if (body.remove && body.remove.length > 0) {
          // update sdk roster info in main session
          updateUserRosterInfoToSDK({
            removeUserIds: message.body.remove.map(({ id }) => id),
            isFromMainSession: 1,
            mediaActionType: sdkIvTypeKeyEnum.SHARING_DECODE,
          });
          updateUserRosterInfoToSDK({
            removeUserIds: message.body.remove.map(({ id }) => id),
            isFromMainSession: true,
            mediaActionType: sdkIvTypeKeyEnum.AUDIO_DECODE,
          });
          dispatch(stopSharingFromMainSession(body.remove));
          storeLeaveParticipant(getState(), message, false);
          dispatch(
            removeMainSessionAttendeeList(removeDuplicates(body.remove)),
          );
        }
        dispatch(saveWebSDKUserThunk(message.body, 'mainSessionForBO'));
        dispatch(handleBoActivityStatusFromRosterIndication(message));
        break;
      }
      case socketEventTypes.WS_CONF_HOST_CHANGE_INDICATION: {
        const {
          body: { bHost },
        } = message;
        dispatch(getHostChangeRes(message));
        dispatch(handleHostUpdateIndication(message));
        dispatch(setModerator(bHost));
        break;
      }
      case socketEventTypes.WS_CONF_COHOST_CHANGE_INDICATION: {
        const {
          body: { bCoHost },
        } = message;
        dispatch(coHostChange(bCoHost));
        dispatch(setModerator(bCoHost));
        break;
      }
      case socketEventTypes.WS_CONF_BO_JOIN_RES: {
        const { body } = message;
        dispatch(handleBoJoinRes(body));
        break;
      }
      case socketEventTypes.WS_CONF_ATTRIBUTE_INDICATION: {
        updateMeetingAttrInBo(dispatch, message);
        break;
      }
      case socketEventTypes.WS_CONF_OPTION_INDICATION: {
        updateOptInStorage(message);
        break;
      }
      case socketEventTypes.WS_CONF_END_INDICATION: {
        dispatch(meetingActions.endMeetingJob(message));
        // notify partner device to exit meeting
        if (isExternalControlledMode()) {
          externalController.notifyConnectorZoomMsg(
            CONTROL_MODE_ZOOM_MSG_TYPE.RWG,
            type,
            message,
          );
        }
        break;
      }
      case socketEventTypes.WS_SHARING_RECEIVING_CHL_READY_INDICATION: {
        dispatch(receiveSharingChannelReady(message.body, true));
        break;
      }
      case socketEventTypes.WS_SHARING_RECEIVING_CHL_CLOSE_INDICATION: {
        const { ssrc } = message.body;
        dispatch(receiveSharingChannelClose(ssrc, true));
        break;
      }
      case socketEventTypes.WS_CONF_START_BO_BROADCAST_VOICE_INDICATION: {
        const { bStatus } = message.body;
        dispatch(setIsRecvBroadcastVoice(!!bStatus));
        break;
      }
      case socketEventTypes.WS_AUDIO_ASN_INDICATION: {
        dispatch(getAsn(message, true));
        break;
      }
      case socketEventTypes.WS_CONF_NOTIFY_USER_NETWORK_QUALITY_INDICATION: {
        dispatch(updateOtherNetworkQuality(message.body));
        break;
      }
      case socketEventTypes.WS_AUDIO_ENCRYPT_KEY_INDICATION: {
        const { encryptKey, additionalType } = message.body;
        dispatch(
          setMainSessionAudioEncryptInfo({ encryptKey, additionalType }),
        );
        break;
      }

      case socketEventTypes.WS_SHARING_ENCRYPT_KEY_INDICATION: {
        const { encryptKey, additionalType } = message.body;
        dispatch(
          setMainSessionShareEncryptInfo({ encryptKey, additionalType }),
        );
        break;
      }

      default:
        return false;
    }
    return false;
  };

export const createPreAssignBoRooms = () => (dispatch, getState) => {
  const {
    attendeesList: { attendeesList },
    breakoutRoom: { hugeBo, preAssignBoList, hasEverBeenPreAssignedDict },
  } = getState();
  if (preAssignBoList && preAssignBoList.length > 0) {
    const nextHasEverBeenPreAssignedDict = { ...hasEverBeenPreAssignedDict };
    dispatch(setPreAssignmentEnabled(true));
    dispatch(resetRoomLocalId());
    dispatch(resetAllRooms());
    dispatch(setHasEverBeenPreAssignedDict({}));

    const roomSize = preAssignBoList.length;
    if (roomSize > getMaxRoomSize(hugeBo)) {
      dispatch(setBoLoading(false));
      return;
    }
    dispatch(setPreRoomSize(roomSize));
    const rooms = [];
    preAssignBoList.forEach(({ BoName, email }, index) => {
      rooms.push({
        topic: BoName,
        index,
      });
      const attendeeIdList = getUserGuidsFromUserEmails(email, attendeesList);
      attendeeIdList.forEach((userGUID) => {
        nextHasEverBeenPreAssignedDict[userGUID] = true;
      });
      addDraftRoom({
        name: BoName,
        requestId: index,
        attendeeIdList,
        emails: email,
      });
    });
    const data = {
      evt: socketEventTypes.WS_CONF_BO_TOKEN_BATCH_REQ,
      body: rooms,
    };
    dispatch(setHasEverBeenPreAssignedDict(nextHasEverBeenPreAssignedDict));
    dispatch(sendSocketMessage(data));
    dispatch(setRoomLocalId(roomSize));
    // we can get the value of data.seq after sendSocketMessage
    // update draftRoom's requestId with data.seq
    updateDraftRooms(data.seq);
  } else {
    dispatch(setBoLoading(false));
  }
};

export const sendFetchPreAssignDocRequest = () => (dispatch, getState) => {
  const {
    breakoutRoom: {
      attendee: { status },
    },
  } = getState();
  if (isNotInRoom(status)) {
    const data = {
      evt: socketEventTypes.WS_CONF_BO_PRE_ASSIGN_REQ,
      body: {},
    };
    dispatch(setBoLoading(true));
    dispatch(sendSocketMessage(data));
  }
};

// click 'yes' in 'Do you want to recover to the pre-assigned rooms? All existing rooms will be replaced.'
export const loadPreAssignRooms = () => (dispatch, getState) => {
  const state = getState();
  const {
    breakoutRoom: { preAssignBoList },
  } = state;
  const currentUserIsAbleToManageBo =
    currentUserIsAbleToManageBoSelector(state);
  if (!currentUserIsAbleToManageBo) {
    return;
  }
  dispatch(setBoLoading(true));
  if (preAssignBoList.length === 0) {
    dispatch(sendFetchPreAssignDocRequest());
    dispatch(resetRoomLocalId());
    dispatch(resetAllRooms());
  } else {
    // get pre assign bo list from cache and create bo rooms
    dispatch(createPreAssignBoRooms());
  }
};

export const handleBoPreAssignRes = (body) => (dispatch, getState) => {
  const {
    breakoutRoom: { roomList },
  } = getState();
  const { proto } = body;
  const preAssignBoList = parseBOPreAssignData(proto);
  if (preAssignBoList && preAssignBoList.length > 0) {
    dispatch(setHasReceivedPreAssignRes(true));
    dispatch(setPreAssignmentEnabled(true));
    // store pre assign bo list to cache
    dispatch(setPreAssignBoList(preAssignBoList));

    const nextToAssignDict = {};
    dispatch(addPreAssignmentToAssignDict(nextToAssignDict));
    if (roomList.length === 0) {
      dispatch(createPreAssignBoRooms());
    } else {
      dispatch(setBoLoading(false));
    }
  } else {
    if (roomList.length === 0) {
      dispatch(setRoomLocalId(0));
    }
    dispatch(setBoLoading(false));
  }
};

export const assignPreAssignedUser =
  (newPreAssignedAttendees) => (dispatch, getState) => {
    setTimeout(() => {
      const {
        attendeesList: { attendeesList },
        breakoutRoom: {
          preAssignmentToAssignDict,
          roomList,
          preAssignBoList,
          controlStatus,
          mainSessionAttendeeList,
          hasEverBeenPreAssignedDict,
        },
      } = getState();
      const usersWithRooms = [];
      const currentRoomInProgress = isRoomInProgress(controlStatus);
      const nextHasEverBeenPreAssignedDict = { ...hasEverBeenPreAssignedDict };

      newPreAssignedAttendees.forEach((attendee) => {
        const { userEmail, userGUID } = attendee;

        const isFoundNotInWaitingRoom =
          !!mainSessionAttendeeList.find(
            (attendeeInStore) =>
              attendeeInStore.userGUID === userGUID &&
              attendeeInStore.bHold === false,
          ) ||
          !!attendeesList.find(
            (attendeeInStore) =>
              attendeeInStore.userGUID === userGUID &&
              attendeeInStore.bHold === false,
          );
        if (!isFoundNotInWaitingRoom) return;

        const targetRoomBoId = preAssignmentToAssignDict[userEmail];
        const preAssignBo = preAssignBoList.find(
          (bo) => bo.email && bo.email.indexOf(userEmail) !== -1,
        );
        if (!preAssignBo) return;

        const targetRoom = roomList.find(
          (room) =>
            room.boId === targetRoomBoId || room.name === preAssignBo.BoName,
        );

        if (!targetRoom || !userGUID) return;

        const previousAttendeeIdList = targetRoom.attendeeIdList;
        if (previousAttendeeIdList.indexOf(userGUID) !== -1) return;

        const assignedAttendeeIds = [...previousAttendeeIdList, userGUID];

        dispatch(
          assignAttendeeIntoBoRoom({
            boId: targetRoom.boId,
            assignedAttendeeIds,
          }),
        );

        nextHasEverBeenPreAssignedDict[userGUID] = true;

        if (currentRoomInProgress) {
          const userWithRoom = {
            userGUID,
            targetBID: targetRoom.boId,
          };
          usersWithRooms.push(userWithRoom);
        }
      });

      dispatch(setHasEverBeenPreAssignedDict(nextHasEverBeenPreAssignedDict));

      if (currentRoomInProgress && usersWithRooms.length) {
        dispatch(requestAssignBatchUserToBo(usersWithRooms));
      }
    }, 1500);
  };

export const showConfirmCloseBoDialog = () => (dispatch, getState) => {
  const {
    breakoutRoom: {
      options: { needCountDown, waitseconds },
    },
  } = getState();
  let contentTitle = CLOSE_BO_CONFIRM_CONTENT1;
  if (needCountDown) {
    /* eslint-disable-next-line @babel/new-cap */
    contentTitle = CLOSE_BO_CONFIRM_CONTENT2(waitseconds);
  }
  dispatch(toggleInstanceOpen(true));
  const defaultOkButtonProps = {
    type: 'error',
    size: 'default',
    className: 'zm-btn-legacy',
  };
  const cancelButtonProps = {
    type: 'default',
    size: 'default',
    className: 'zm-btn-legacy',
  };
  Modal.confirm({
    className: 'zm-modal-legacy',
    okText: BREAKOUTROOMS_CLOSE_ALL_ROOMS,
    okButtonProps: defaultOkButtonProps,
    cancelText: DIALOG_CANCEL,
    cancelButtonProps,
    contentLabel: contentTitle,
    title: CLOSE_BO_CONFIRM_TITLE,
    content: contentTitle,
    ref: instantDialogRef,
    onAfterClose: () => {
      dispatch(toggleInstanceOpen(false));
    },
    onOk: () => {
      dispatch(closeBreakoutRoom());
    },
  });
};

export const getSaveBoErrorContentText = (errorCode, baseUrl) => {
  if (!Object.values(SAVE_BO_ERROR_TYPES).includes(errorCode)) {
    return null;
  }
  /* eslint-disable-next-line @babel/new-cap */
  const errorTip = UNABLE_SAVE_BO_ERROR_TIP(ELEMENT_PLACEHOLDER);
  /* eslint-disable-next-line @babel/new-cap */
  const errorWords = UNABLE_SAVE_BO_ERROR_TIP(
    UNABLE_SAVE_BO_ERROR_TIP_PLACEHOLDER,
  );
  const errorTipContent = reactStringReplace(
    errorTip,
    ELEMENT_PLACEHOLDER,
    () => (
      <WcLink link={`${baseUrl}/meeting/schedule`}>
        {UNABLE_SAVE_BO_ERROR_TIP_PLACEHOLDER}
      </WcLink>
    ),
  );
  let errorDesc = '';
  if (errorCode === SAVE_BO_ERROR_TYPES.MAX_LIMIT) {
    errorDesc = UNABLE_SAVE_BO_ERROR1;
  }
  if (errorCode === SAVE_BO_ERROR_TYPES.NAME_EXIST) {
    errorDesc = UNABLE_SAVE_BO_ERROR2;
  }
  return (
    <div tabIndex={0} aria-label={`${errorDesc}${errorWords}`}>
      <span>{errorDesc}</span>
      {errorTipContent}
    </div>
  );
};

export const showSaveBoErrorDialog = (errorCode) => (dispatch, getState) => {
  const {
    meeting: { baseUrl },
  } = getState();
  const contentTitle = Object.values(SAVE_BO_ERROR_TYPES).includes(errorCode)
    ? UNABLE_SAVE_BO_TEXT
    : /* eslint-disable-line @babel/new-cap */ UNABLE_SAVE_BO_COMMON_ERROR(
        errorCode,
      );
  const content = getSaveBoErrorContentText(errorCode, baseUrl);
  dispatch(toggleInstanceOpen(true));
  const defaultOkButtonProps = {
    type: 'primary',
    size: 'default',
    className: 'zm-btn-legacy',
  };
  Modal.confirm({
    className: 'zm-modal-legacy',
    okText: DIALOG_OK,
    okButtonProps: defaultOkButtonProps,
    okCancel: false,
    contentLabel: contentTitle,
    title: contentTitle,
    content,
    ref: instantDialogRef,
    onAfterClose: () => {
      dispatch(toggleInstanceOpen(false));
    },
  });
};

export const handleSaveBoRes = (body) => (dispatch) => {
  const { errorCode } = body;
  if (errorCode === 0) {
    dispatch(setSaveBoSuccessVisible(true));
  } else {
    dispatch(showSaveBoErrorDialog(errorCode));
  }
};

export const saveBoRoomsAction = (name, applyAll) => (dispatch, getState) => {
  const {
    breakoutRoom: { roomList },
    attendeesList: { attendeesList },
    meeting: { isHost },
  } = getState();
  if (!isHost || roomList.length === 0) {
    return;
  }

  const hasDuplicateBO =
    _.uniq(roomList.map((room) => room.name)).length !== roomList.length;

  if (hasDuplicateBO) {
    dispatch(showHasDuplicateBONameDialog());
    return;
  }

  const boRooms = {};
  // get json data like doc says
  roomList.forEach((room) => {
    const users = room.attendeeIdList
      .map((guid) => {
        const target = attendeesList.find(
          (attendee) => attendee.userGUID === guid,
        );
        if (target) {
          const isZrUser = isZRMultiStreamVideoUser({
            caps: target.caps,
            bMultiStreamVideoUser: target.bMultiStreamVideoUser,
          });
          return {
            email: target?.userEmail,
            isZr: isZrUser ? 1 : -1,
            zrUserId: isZrUser ? target.zoomID : '',
          };
        }
        return false;
      })
      .filter(Boolean);
    boRooms[room.name] = users;
  });
  const gzipBoInfo = zlib.gzipSync(JSON.stringify(boRooms)).toString('base64');
  const data = {
    evt: socketEventTypes.WS_CONF_BO_SAVE_GROUP_REQ,
    body: {
      groupName: name,
      boInfo: gzipBoInfo,
      applyAll: applyAll ? '1' : '0',
    },
  };
  dispatch(sendSocketMessage(data));
};

export const showHasDuplicateBONameDialog = () => (dispatch) => {
  const content = SAVE_BO_DUPLICATE_BO_NAME_ERROR_DIALOG_TEXT;
  const defaultOkButtonProps = {
    type: 'primary',
    size: 'default',
    className: 'zm-btn-legacy',
  };
  Modal.confirm({
    className: 'zm-modal-legacy',
    okText: DIALOG_OK,
    okButtonProps: defaultOkButtonProps,
    okCancel: false,
    contentLabel: content,
    title: content,
    ref: instantDialogRef,
    onAfterClose: () => {
      dispatch(toggleInstanceOpen(false));
    },
  });
};

export const startOrStopBroadcastVoiceToBo =
  (isStart = true) =>
  (dispatch) => {
    const data = {
      evt: socketEventTypes.WS_CONF_BO_BROADCAST_VOICE_REQ,
      body: {
        broadcastVoice: !!isStart,
      },
    };
    dispatch(sendSocketMessage(data));
    dispatch(setIsBroadcastVoice(!!isStart));
    dispatch(enableBroadcastToBo(!!isStart));
  };

export const enableBroadcastToBo = (enable) => () => {
  globalVariable.avSocket.sendSocket(
    AVNotifyMediaSDKTypes.ENABLE_BROADCAST_TO_BO,
    {
      enable,
    },
  );
};

export const showUnmuteToast = () => (dispatch, getState) => {
  const TOAST_KEY = 'broadcast_voice_mute_toast';
  const isMuted = isBroadcastVoiceButMutedSelector(getState());
  if (isMuted) {
    AliveToast.uniqueToast({
      name: TOAST_KEY,
      aliveTime: 5000,
      message: MUTED_TOAST,
      btnComponent: (
        <ZoomButton
          type="primary"
          styleProps={{ fontWeight: 'bold' }}
          onClick={() => {
            dispatch(handleAudioButtonClickThunk(undefined, false));
            AliveToast.close(TOAST_KEY);
          }}
        >
          {UNMUTE_TEXT}
        </ZoomButton>
      ),
    });
    return false;
  }
  return true;
};
