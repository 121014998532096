import meetingConfig from 'meetingConfig';
import { LOCALSTORAGE_KEYS } from '../constant';
import { easyStore } from '../easy-store';
import { isEnableRWGSupportWebRTC, isEnableWebRTC } from '../op-feature-option';
import { isExternalControlledMode } from '../util';
import { USE_WEBRTC_STRATEGY } from '../../features/settings/constant';
import { useWebRTCStrategyErrorNo } from '../../features/settings/enum';

const mediaFeatureOptions = meetingConfig.mediaFeatureOptions;

/**
 * get rtc or option value from AB option or url params for DEV
 * @param {*} field rtc | option
 * @param {*} backup AB option config value
 */
export const getOptionInParameters = (field, backup) => {
  if (CLIENT_ENV !== 'development') {
    return parseInt(backup ?? 0, 16);
  }
  const urlParams = new URLSearchParams(window.location.search);
  // Get a specific parameter value by name
  let option = urlParams.get(field);
  if (!option) {
    option = backup;
  }
  option = parseInt(option ?? 0, 16);

  return option;
};

/**
 * get webRTC AB option original value: 0/3/7/11/15
 */
export const getWebRTCABOption = () => {
  return getOptionInParameters('rtc', isEnableWebRTC());
};

/**
 * 4th bit: show or hide the switch of 'Use WebRTC'
 */
export const getShowUseWebRTCStrategySelector = _.memoize(() => {
  if (!(getWebRTCABOption() & 0b1000)) {
    return false;
  }

  const { shouldUseWebRTC, errNo } =
    JsMediaSDK_Instance?.util?.evaluateWebRTCStrategy?.(
      USE_WEBRTC_STRATEGY.ENABLED,
      mediaFeatureOptions,
      getWebRTCBlackList(),
    ) ?? {};

  // Hit blacklist or not in whitelist
  if (
    !shouldUseWebRTC &&
    (errNo === useWebRTCStrategyErrorNo.DEVICE_ON_BLACKLIST ||
      errNo === useWebRTCStrategyErrorNo.DEVICE_NOT_ON_WHITELIST)
  ) {
    return false;
  }

  return true;
});

/**
 * get default selector value from mediaSDK
 */
export const getUseWebRTCStrategyDefaultValue = _.memoize(() => {
  const defaultOption = (
    JsMediaSDK_Instance?.util?.getWebRTCStrategyOptions?.(
      mediaFeatureOptions,
    ) || []
  ).find(({ isDefault }) => isDefault);
  return defaultOption ? defaultOption.value : 0;
});

/**
 * get the selector value from mediaSDK or the localstorage
 */
export function getUseWebRTCStrategy() {
  if (!getShowUseWebRTCStrategySelector()) {
    return getUseWebRTCStrategyDefaultValue();
  } else {
    return (
      easyStore.easyGet(LOCALSTORAGE_KEYS.use_webRTC_strategy) ??
      getUseWebRTCStrategyDefaultValue()
    );
  }
}

/**
 * get enable webRTC from AB option and feature toggle
 */
export const getEnableWebRTCFromABAndFT = _.memoize(() => {
  if (isExternalControlledMode()) return false;

  // if watermark is enable, webRTC need to be disable
  const watermarkEnableTurnOnOff =
    meetingConfig.watermark?.enableTurnOnOff ?? false;
  const isEnableMeetingWatermark =
    meetingConfig.meetingOptions?.isEnableMeetingWatermark ?? false;
  if (watermarkEnableTurnOnOff || isEnableMeetingWatermark) {
    return false;
  }

  if (!JsMediaSDK_Instance?.util?.isWebRTCFeatureEnabled?.(mediaFeatureOptions))
    return false;

  return !!meetingConfig.commonWCFeatureConfig?.mobileWebClientConfig
    ?.desktop_webrtc;
});

export const getWebRTCBlackList = () => {
  let webRTCBlackList;
  try {
    webRTCBlackList = JSON.parse(
      meetingConfig.webMediaBlockConfig,
    ).WebRTCBlacklist;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return webRTCBlackList;
};

export const shouldUseWebRTCByMediaSDK = (useWebRTCStrategy) => {
  const { shouldUseWebRTC } =
    JsMediaSDK_Instance?.util?.evaluateWebRTCStrategy?.(
      useWebRTCStrategy,
      mediaFeatureOptions,
      getWebRTCBlackList(),
    ) ?? {};
  return shouldUseWebRTC;
};

const memomizedUseWebRTCByMediaSDK = _.memoize(shouldUseWebRTCByMediaSDK);

export const getFinalUseWebRTC = () => {
  if (meetingConfig.isSimuliveWebinar) return false;
  // if webrtc unavailable, force to use WASM
  if (easyStore.easyGet('WebRTC_Failed')) return false;
  return (
    getEnableWebRTCFromABAndFT() &&
    memomizedUseWebRTCByMediaSDK(getUseWebRTCStrategy())
  );
};

export const getRWCOption = () => {
  if (!getFinalUseWebRTC()) {
    return 0;
  }
  return getOptionInParameters('option', isEnableRWGSupportWebRTC());
};
